import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {FetchData} from '../Redux/actions/data';
import {DataState} from '../Redux/type';
import {VStack, useToast} from '@chakra-ui/react';
import LoadingSpinner from '../Data/loadingSpinner';

const StartPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const PostData = useSelector((state: DataState) => {
    return state.postData;
  });
  const toast = useToast();
  useEffect(() => {
    const triggerWarningToast = () => {
      toast({
        title: `OOPS! We're having trouble loading your report.`,
        status: 'warning',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    };
    const trigger500Toast = () => {
      toast({
        title: `Hmmm... Somethings not quite right on our end.`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    };
    dispatch(FetchData(triggerWarningToast, trigger500Toast));
  }, [dispatch, toast]);
  useEffect(() => {
    if (PostData?.post && PostData.post.fetched) {
      history.push(`/assessments/${PostData.post.id}`);
    }
  }, [PostData.post, history]);
  return (
    <VStack color="white" gap={6} h="100vh" justify="center" align="center">
      {/* <Heading align="center" maxW="80%">
        We are getting your assessment ready for you
      </Heading> */}
      <LoadingSpinner />
    </VStack>
  );
};

export default StartPage;
