import React, {useEffect, useState} from 'react';
import QuestionContainer from '../Containers/QuestionContainer';
import {Flex} from '@chakra-ui/layout';
import {Image, Text, useToast} from '@chakra-ui/react';
import Footer from '../Containers/FooterContainer';
import './styles.css';
import {AnswerContext, CounterContext} from '../Context';
import {useMediaQuery} from 'react-responsive';
import {useSelector, useDispatch} from 'react-redux';
import {FetchProgress, PostProgress, PutProgress} from '../Redux/actions/data';
import {DataState} from '../Redux/type';
import LoadingSpinner from '../Data/loadingSpinner';
import {useHistory, useParams} from 'react-router-dom';
import PutDataRearrange from './PutDataRearrange';
import FetchRearrange from './FetchRearrange';
const nextOption = (current: number, questionSubProgress: number) => {
  if (current === 0 || current === 6 || current === 13) {
    return {doNextAfter: 1, current: questionSubProgress, lastMax: 0};
  } else if (current === 1 || current === 7 || current === 13) {
    return {doNextAfter: 0, current: questionSubProgress, lastMax: 1};
  }
  return {doNextAfter: 0, current: questionSubProgress, lastMax: 0};
};

interface FooterProgressProps {
  progress: {
    total: number;
    current: number;
  };
  index: number;
}

const FooterProgress: React.FC<FooterProgressProps> = ({progress, index}) => {
  if (index < progress.current) {
    return <Flex className="Footer-Answered"></Flex>;
  } else if (index === progress.current) {
    return <Flex className="Footer-Answering"></Flex>;
  }
  return <Flex className="Footer-ToAnswer"></Flex>;
};

const Home: React.FC = () => {
  const [questionCounter, setQuestionCounter] = useState(0);
  const [sectionCounter, setSectionCounter] = useState(0);
  const {assessmentID} = useParams<{assessmentID: string}>();
  const dispatch = useDispatch();
  const [totalProgress, setTotalProgress] = useState(0);
  const [questionSubProgress, setQuestionSubProgress] = useState(0);
  const [finalLoading, setFinalLoading] = useState(false);
  const QuestionRedux = useSelector((state: DataState) => {
    return state.fetchData;
  });
  const toast = useToast();
  const triggerWarningToast = () => {
    toast({
      title: `OOPS! We're having trouble loading your report.`,
      status: 'warning',
      position: 'top-right',
      isClosable: true,
      duration: 5000,
    });
  };
  const trigger500Toast = () => {
    toast({
      title: `Hmmm... Somethings not quite right on our end.`,
      status: 'error',
      position: 'top-right',
      isClosable: true,
      duration: 5000,
    });
  };
  const TotalQuestions = 15;
  useEffect(() => {
    const triggerWarningToast = () => {
      toast({
        title: `OOPS! We're having trouble loading your report.`,
        status: 'warning',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    };
    const trigger500Toast = () => {
      toast({
        title: `Hmmm... Somethings not quite right on our end.`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    };
    dispatch(FetchProgress(assessmentID, triggerWarningToast, trigger500Toast));
  }, [assessmentID, dispatch, toast]);
  const history = useHistory();
  const [answers, setAnswers] = useState<string[][]>(() => {
    const newArray = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ];
    return newArray;
  });
  useEffect(() => {
    if (QuestionRedux?.questionData && QuestionRedux.questionData.id !== '-1') {
      setAnswers(FetchRearrange(QuestionRedux.questionData.responses));
    }
  }, [QuestionRedux]);
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const nextPart = (maxProgress: number) => {
    if (questionSubProgress < maxProgress) {
      setQuestionSubProgress((prev) => prev + 1);
    } else {
      nextQuestion();
    }
  };

  const prevPart = (maxProgress: number) => {
    if (questionSubProgress > 0) {
      setQuestionSubProgress((prev) => prev - 1);
    } else {
      prevQuestion(maxProgress);
    }
  };

  const prevQuestion = (lastMax: number) => {
    if (totalProgress !== 0) {
      setTotalProgress(totalProgress - 1);
      setQuestionSubProgress(lastMax);
    }
    if (questionCounter === 0) {
      if (sectionCounter > 0) {
        setQuestionCounter(
          QuestionRedux.questionData.questions[sectionCounter - 1].length - 1
        );
        setSectionCounter(sectionCounter - 1);
      }
    } else {
      setQuestionCounter(questionCounter - 1);
      // setQuestionSubProgress(lastMax); //!: May need to uncomment this
    }
  };
  const nextQuestion = () => {
    if (totalProgress === TotalQuestions - 1) {
      setFinalLoading(true);
      const payload = {
        client: {
          first_name: answers[totalProgress][0],
          age_range: {
            low: parseInt(answers[totalProgress][1], 10),
            high: parseInt(answers[totalProgress][1], 10),
          },
        },
        submission: PutDataRearrange(answers, 'post'),
      };

      dispatch(
        PostProgress(
          assessmentID,
          payload,
          triggerWarningToast,
          trigger500Toast
        )
      );
      setTimeout(() => {
        setFinalLoading(false);
        history.push(`/assessments/${assessmentID}/report`);
      }, 3000);
    } else {
      dispatch(
        PutProgress(
          assessmentID,
          PutDataRearrange(answers, 'put'),
          triggerWarningToast,
          trigger500Toast
        )
      );
      setTotalProgress(totalProgress + 1);
      if (
        questionCounter <
        QuestionRedux.questionData.questions[sectionCounter].length - 1
      ) {
        setQuestionCounter(questionCounter + 1);
      } else {
        setQuestionCounter(0);
        setSectionCounter(sectionCounter + 1);
      }
      setQuestionSubProgress(0);
    }
  };
  return finalLoading ? (
    <Flex className="Loading-Container">
      <Text className="Loading-Text">
        Our behavioral engines are now running, your report should be ready in a
        moment...
      </Text>
      <Flex>
        <Image
          src="https://res.cloudinary.com/dmrpikki0/video/upload/v1634299125/Solera-Assessment/animation_rdjxaz.gif"
          alt="Loading"
        />
      </Flex>
    </Flex>
  ) : QuestionRedux.questionData ? (
    QuestionRedux.questionData.id === '-1' ? (
      <Flex alignItems="center" justifyContent="center" w="100%" minH="100vh">
        <h1 className="Report-NotGenerated">{`${QuestionRedux?.error?.message}`}</h1>
      </Flex>
    ) : (
      <CounterContext.Provider value={{questionCounter, sectionCounter}}>
        <AnswerContext.Provider value={{answers, setAnswers}}>
          <Flex
            direction="column"
            width="100%"
            minHeight="100vh"
            position="relative"
          >
            {isMobile && (
              <Flex className="Footer-ProgressSection">
                {Array.from(Array(TotalQuestions)).map((x, index) => (
                  <FooterProgress
                    key={index}
                    index={index}
                    progress={{
                      total: TotalQuestions,
                      current: totalProgress,
                    }}
                  />
                ))}
              </Flex>
            )}
            <Flex mb="30px">
              <QuestionContainer
                questionData={
                  QuestionRedux.questionData.questions[sectionCounter][
                    questionCounter
                  ]
                }
                progress={{total: TotalQuestions, current: totalProgress}}
                nextOptions={nextOption(totalProgress, questionSubProgress)}
              />
            </Flex>
            <Footer
              nextQuestion={nextPart}
              prevQuestion={prevPart}
              progress={{total: TotalQuestions, current: totalProgress}}
              nextOptions={nextOption(totalProgress, questionSubProgress)}
            />
          </Flex>
        </AnswerContext.Provider>
      </CounterContext.Provider>
    )
  ) : (
    <LoadingSpinner />
  );
};

export default Home;
