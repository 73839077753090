import {DataAction, QuestionData} from '../type';
import API_RESPONSE_DATA from '../../Data';
export function fetchData(
  action: DataAction,
  state: QuestionData = {
    ...API_RESPONSE_DATA,
  }
) {
  if (action && action.type === 'FETCH_ASSESSMENT_PROGRESS_SUCCESS') {
    return action.questionData;
  }
  return state;
}

export function postData(
  action: {type: string; post: {fetched: boolean; id: string}},
  state: {fetched: false; id: ''}
) {
  if (action && action.type === 'POST_ASSESSMENT_SUCCESS') {
    return action.post;
  }
  return state;
}
