// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Fonts/Gill-Sans/Gill Sans.otf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./Fonts/Gill-Sans/Gill Sans Bold.otf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./Fonts/Gill-Sans/Gill Sans Medium.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Gill-Sans';\n  src: local('Gill-Sans'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n@font-face {\n  font-family: 'Gill-Sans';\n  src: local('Gill-Sans'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: 'Gill-Sans';\n  src: local('Gill-Sans'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n  font-weight: normal;\n}\nhtml {\n  scroll-behavior: smooth;\n}\n", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB;8DAC4D;AAC9D;;AAEA;EACE,wBAAwB;EACxB;8DACkE;EAClE,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB;8DACoE;EACpE,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB","sourcesContent":["@font-face {\n  font-family: 'Gill-Sans';\n  src: local('Gill-Sans'),\n    url('./Fonts/Gill-Sans/Gill\\ Sans.otf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Gill-Sans';\n  src: local('Gill-Sans'),\n    url('./Fonts/Gill-Sans/Gill\\ Sans\\ Bold.otf') format('truetype');\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: 'Gill-Sans';\n  src: local('Gill-Sans'),\n    url('./Fonts/Gill-Sans/Gill\\ Sans\\ Medium.otf') format('truetype');\n  font-weight: normal;\n}\nhtml {\n  scroll-behavior: smooth;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
