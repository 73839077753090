import AdventurerWeb from '../Assets/adventure-web.svg';
import AnalyzerWeb from '../Assets/analyzer-web.svg';
import ObserverWeb from '../Assets/observer-svg.svg';
import ProtectorWeb from '../Assets/protector-web.svg';
import ResearcherWeb from '../Assets/Researcher-web.svg';
import SeekerWeb from '../Assets/seeker-web.svg';
import StrategizerWeb from '../Assets/strategiser-web.svg';
import ExplorerWeb from '../Assets/explorer-web.svg';

import AdventurerMobile from '../Assets/adventure.svg';
import AnalyzerMobile from '../Assets/analyzer-mobile.svg';
import ObserverMobile from '../Assets/observor-mobile.svg';
import ProtectorMobile from '../Assets/protector-mobile.svg';
import ResearcherMobile from '../Assets/Researcher-mobile.svg';
import SeekerMobile from '../Assets/seeker-mobile.svg';
import StrategizerMobile from '../Assets/strategiser-mobile.svg';
import ExplorerMobile from '../Assets/explorer-mobile.svg';

import AdventurerTablet from '../Assets/Adventure - Tablet.svg';
import AnalyzerTablet from '../Assets/Analyzer - Tablet.svg';
import ObserverTablet from '../Assets/Observer - Tablet.svg';
import ProtectorTablet from '../Assets/Protector - Tablet.svg';
import ResearcherTablet from '../Assets/Researcher - Tablet.svg';
import SeekerTablet from '../Assets/Seeker- Tablet.svg';
import StrategizerTablet from '../Assets/Strategizer- Tablet.svg';
import ExplorerTablet from '../Assets/explorer-tablet.svg';

const Section1Bg = (choice: string, screen: string) => {
  if (screen === 'mobile') {
    if (choice === 'seeker_report_header_image') {
      return SeekerMobile;
    } else if (choice === 'researcher_report_header_image') {
      return ResearcherMobile;
    } else if (choice === 'protector_report_header_image') {
      return ProtectorMobile;
    } else if (choice === 'observer_report_header_image') {
      return ObserverMobile;
    } else if (choice === 'analyzer_report_header_image') {
      return AnalyzerMobile;
    } else if (choice === 'adventurer_report_header_image') {
      return AdventurerMobile;
    } else if (choice === 'explorer_report_header_image') {
      return ExplorerMobile;
    }
    return StrategizerMobile;
  } else if (screen === 'tablet') {
    if (choice === 'seeker_report_header_image') {
      return SeekerTablet;
    } else if (choice === 'researcher_report_header_image') {
      return ResearcherTablet;
    } else if (choice === 'protector_report_header_image') {
      return ProtectorTablet;
    } else if (choice === 'observer_report_header_image') {
      return ObserverTablet;
    } else if (choice === 'analyzer_report_header_image') {
      return AnalyzerTablet;
    } else if (choice === 'adventurer_report_header_image') {
      return AdventurerTablet;
    } else if (choice === 'explorer_report_header_image') {
      return ExplorerTablet;
    }
    return StrategizerTablet;
  } else {
    if (choice === 'seeker_report_header_image') {
      return SeekerWeb;
    } else if (choice === 'researcher_report_header_image') {
      return ResearcherWeb;
    } else if (choice === 'protector_report_header_image') {
      return ProtectorWeb;
    } else if (choice === 'observer_report_header_image') {
      return ObserverWeb;
    } else if (choice === 'analyzer_report_header_image') {
      return AnalyzerWeb;
    } else if (choice === 'adventurer_report_header_image') {
      return AdventurerWeb;
    } else if (choice === 'explorer_report_header_image') {
      return ExplorerWeb;
    }
    return StrategizerWeb;
  }
};
export default Section1Bg;
