import {darken} from '@chakra-ui/theme-tools';

export const buttonStyles = {
  components: {
    Button: {
      variants: {
        previous: {
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '18px',
          lineHeight: '40px',
          height: '52px',
          width: '140px',
          borderRadius: '60px',
          border: '2px solid #003865',
          color: 'blue.dark',
          _hover: {
            bg: darken('white', 8),
          },
        },
        next: {
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '18px',
          lineHeight: '22px',
          borderRadius: '60px',
          padding: '4px 54px',
          height: '52px',
          width: '200px',
          background: '#ffa400',
          color: 'blue.dark',
          _hover: {
            bg: darken('#ffa400', 8),
            _disabled: {
              background: '#ffa400',
            },
          },
        },
      },
    },
  },
};
