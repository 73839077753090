const ExampleReport = {
  client: {
    first_name: '0',
  },
  report: {
    header_image: 'SOME-IMAGE-NAME-OR-URL-HERE',
    scores: {
      persona: 'seeker',
      persona_description:
        'Seekers are willing to take comparatively more risk than others as long as they have enough knowledge of the potential outcomes, though they tend to be more focused on the short term.',
      focus: {
        value: 32,
        bucket: 'high',
        what_it_means: {
          title: 'What does Future Focus Mean?',
          body: "One of life's recurring dilemmas is the trade-off between immediate benefits and potentially greater benefits in the future. Whether it’s about our career, health, or wealth, instant gratification and future well-being often conflict. According to research, people with a more long-term perspective generally have high connectedness to their future self and generally have higher life satisfaction.",
        },
        what_is_awesome: {
          title: 'What is awesome about it?',
          body: 'You tend to live in the present and make choices that increase current enjoyment or gratification. Your focus on the present enables you to cut your losses quickly and move on.',
        },
        what_to_watchout_for: {
          title: 'What do I need to watch out for?',
          body: 'Most important goals like health, retirement, career development, etc. require long-term thinking. Focusing too much on the present and not considering the future consequences of current decisions may lead someone with high present focus to neglect taking action today if there are no immediate rewards and jeopardize their ability to achieve important future goals.',
        },
      },
      risk: {
        value: 27,
        bucket: 'low',
        what_it_means: {
          title: 'What does Avoid Risk Mean?',
          body: 'Simply put, risk aversion refers to the tendency to be more sensitive to losses than to gains. The higher our risk aversion, the greater the emotional and psychological impact losses have on our decision-making. Research has shown that the pain of losing an amount of money is psychologically twice as powerful as the pleasure of gaining the same value.',
        },
        what_is_awesome: {
          title: 'What is awesome about it?',
          body: 'Your low aversion to risk means you have a higher tolerance for losses than most people, which can be a great benefit to long-term investing. You tend to take larger risks than others, accepting a higher degree of volatility in your investments and faring better than others when markets are highly active.',
        },
        what_to_watchout_for: {
          title: 'What do I need to watch out for?',
          body: 'Your low risk aversion can lead to accepting more risk than is advisable. For example, investors with such a profile might not only be comfortable with an aggressive growth portfolio, they might also be attracted to the idea of making risky short-term trades. The challenge may be in learning to be careful and judicious in investment management, ensuring that quality investments carry appropriate risk levels and suitable rewards.',
        },
      },
      certainty: {
        value: 67,
        bucket: 'medium',
        what_it_means: {
          title: 'What does Desire for Certainty Mean?',
          body: 'The degree to which you prefer certainty over uncertainty. Those with a higher certainty preference may find decisions with unknown or ambiguous outcomes more anxiety-provoking which can lead to inaction and avoidance.',
        },
        what_is_awesome: {
          title: 'What is awesome about it?',
          body: 'Medium certainty preference tends to lead to well-thought-out decisions and putting into perspective the potential gains of time-sensitive decisions. It can also reduce the propensity to overestimate your ability to predict and affect the future and helps you set realistic attitudes toward outcomes.',
        },
        what_to_watchout_for: {
          title: 'What do I need to watch out for?',
          body: 'People with medium certainty preference may neglect to perform detailed analyses or dismiss opposing viewpoints, which could potentially lead to taking inappropriate risks and rash decisions.',
        },
      },
    },
    your_journey: [
      {
        title: 'Persona Strengths',
        subtitle:
          'Your Persona strengths can help you deal with your concerns effectively, because you have a natural ability to:',
        bullets: [
          'Able to stomach short-term volatility',
          'Ability to take quick decisions',
          'Naturally inclined to have realistic expectations',
        ],
      },
      {
        title: 'Your Concerns',
        subtitle:
          'Your Persona strengths can help you deal with your concerns effectively, because you have a natural ability to:',
        bullets: [
          'Rising medical expenses/ Unexpected expenses',
          'Not prepared enough for it',
          'Inflation',
        ],
      },
      {
        title: 'What to watch out for',
        subtitle:
          'To ensure you are on track to achieve your dreams, watch out if you are:',
        bullets: [
          'Making decisions based on the present without thinking about the future carefully',
          'Taking on more risk than may be appropriate for your financial situation',
          "Getting bogged down with 'paralysis by analysis' and delay taking action",
        ],
      },
      {
        title: 'Follow your Dreams',
        subtitle:
          'Your Persona strengths can help you deal with your concerns effectively, because you have a natural ability to:',
        bullets: [
          'Getting a part-time job',
          'Taking educational courses',
          'Joining a community organization',
        ],
      },
    ],
    take_action: {
      advice_first: false,
      education_first: true,
    },
    cta: {
      advice: {
        digital:
          'https://www.tiaa.org/public/offer/insights/retirement-advisor?tc_mcid=bn_syntoniq_adventurer_u45_q122',
        human:
          'https://www.tiaa.org/public/support/contact-us/consultations-seminars?tc_mcid=bn_syntoniq_adventurer_u45_q122',
      },
      education: {
        video:
          'https://www.tiaa.org/public/modal/tiaa-traditional-annuity-video?isModal=true&tc_mcid=bn_syntoniq_adventurer_u45_q122',
        brochure:
          'https://localhost:1234?tc_mcid=bn_syntoniq_adventurer_u45_q122',
        article:
          'https://www.tiaa.org/public/learn/retirement-planning-and-beyond?tc_mcid=bn_syntoniq_adventurer_u45_q122',
      },
      footer: 'https://tiaa.org?tc_mcid=bn_syntoniq_adventurer_u45_q122',
    },
  },
};

export default ExampleReport;
