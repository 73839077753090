let ASSESSMENT_POST_API_URL = '';
let CDN_URL = '';
if (process.env.REACT_APP_NODE_ENV === 'DEV') {
  ASSESSMENT_POST_API_URL =
    'https://ebnuh39vau.us-west-2.awsapprunner.com/v1/assessments';
  CDN_URL =
    'https://s3.us-east-2.amazonaws.com/cdn.financialbehaviorquiz.dev.syntoniq.com';
} else if (process.env.REACT_APP_NODE_ENV === 'STAGE') {
  ASSESSMENT_POST_API_URL =
    'https://fkpemi52ts.us-east-2.awsapprunner.com/v1/assessments';
  CDN_URL =
    'https://s3.us-east-2.amazonaws.com/cdn.financialbehaviorquiz.stage.syntoniq.com';
} else if (process.env.REACT_APP_NODE_ENV === 'PROD') {
  ASSESSMENT_POST_API_URL = 'https://fbqapi.syntoniq.com/v1/assessments';
  CDN_URL =
    'https://s3.us-east-2.amazonaws.com/cdn.financialbehaviorquiz.syntoniq.com';
} else {
  ASSESSMENT_POST_API_URL =
    'https://ebnuh39vau.us-west-2.awsapprunner.com/v1/assessments';
  CDN_URL =
    'https://s3.us-east-2.amazonaws.com/cdn.financialbehaviorquiz.dev.syntoniq.com';
}
const API_RESPONSE_DATA = {
  id: '-1',
  name: 'TIAA POC',
  sections: ['Present Focus', 'Risk Aversion', 'Certainty Preference'],
  questions: [
    [
      {
        question_number: 1,
        question_type: 'MULTI_SLIDER',
        sliders: [
          {
            context: {
              body: 'You have been given a choice between receiving $300 immediately, or another amount 1 year from now.',
              postface:
                'How much would the future amount need to be to make it as attractive as receiving $300 immediately?',
              help_text:
                'Please move the slider bar to indicate the dollar amount that would make the following options in 1 year equally attractive:',
            },
            default: '1350.0',
            min: '300.0',
            max: '3000.0',
            steps: {
              count: 0,
            },
            ticks: false,
            icon: 'some_icon_here',
          },
          {
            context: {
              body: 'You have been given a choice between receiving $300 immediately, or another amount 5 years from now.',
              postface:
                'How much would the future amount need to be to make it as attractive as receiving $300 immediately?',
              help_text:
                'Please move the slider bar to indicate the dollar amount that would make the following options in 5 years equally attractive:',
            },
            default: '1350',
            min: '300.0',
            max: '3000.0',
            steps: {
              count: 0,
            },
            ticks: false,
            icon: 'some_icon_here',
          },
        ],
      },
      {
        question_number: 2,
        question_type: 'CARD',
        context: {
          body: 'Congrats! You are a Winner! You have won $5 million in a lottery today!! You have two choices to claim your prize money: You could either claim the entire $5 million now, or you can choose to receive $700,000 every year for the next 12 years.',
          help_text:
            'Please move the slider bar to indicate the dollar amount that would make the following options [1 year | 5 years] equally attractive:',
          postface:
            'Of course, you will contact your tax/financial advisors, but which one are you leaning towards right now?',
        },
        card: {
          context: {
            left: {
              text: {
                center: '$5 MM Today!',
              },
              value: '1.0',
            },
            right: {
              text: {
                center: '$700K every year for the next 12 years',
              },
              value: '0.0',
            },
          },
          icon: 'some_icon_here',
        },
      },
      {
        question_number: 3,
        question_type: 'RADIO',
        context: {
          preface: 'We all perceive time differently, how do you see it?',
          body: 'When you think about your life and time, how long or short do you feel 5 years is?',
        },
        radio: [
          ['Over in the blink of an eye', '0.0'],
          ['Goes by pretty quickly', '0.3'],
          ['It does seem long', '0.8'],
          ['Feels like a lifetime', '1.0'],
        ],
        icon: 'some_icon_here',
      },
      {
        question_number: 4,
        question_type: 'SLIDER',
        context: {
          preface:
            'Think about the important characteristics that make you the person you are now - your personality, temperament, major likes and dislikes, beliefs, values, ambitions, life goals, and ideals.',
          body: 'Move the slider bar to the point that best reflects how connected you feel with your future-self 5 years from now.',
          help_text:
            'The yellow circle indicates who you are today and the red one, who you will be 5 years from now.',
        },
        slider: {
          default: '0',
          min: '0.0',
          max: '1.0',
          steps: {
            count: 0,
          },
          ticks: false,
        },
        icon: 'some_icon_here',
      },
    ],
    [
      {
        question_number: 5,
        question_type: 'SLIDER',
        context: {
          preface: '',
          body: 'If you had to choose between more job security with a small pay increase and less job security with a big pay increase, which would you pick?',
          help_text:
            'Please move the slider bar to indicate the dollar amount that would make the following options [1 year | 5 years] equally attractive:',
          postface:
            'Of course, you will contact your tax/financial advisors, but which one are you leaning towards right now?',
        },
        slider: {
          //!: Need to get correct values from prasad
          default: '0.5',
          min: '1.0',
          max: '0.0',
          steps: {
            count: 5,
            labels: [
              'Definitely less job security with a big pay increase',
              'Not sure',
              'Definitely more job security with a small pay increase',
            ],
          },
          ticks: false,
        },
        icon: 'some_icon_here',
      },
      {
        question_number: 6,
        question_type: 'SLIDER',
        context: {
          preface:
            'Your trusted friend, an experienced geologist, is putting together a group of investors to fund an exploratory gold mining venture. The initial investment could return 50 to 100 times if successful. If the mine is a bust, the entire investment is worthless. Your friend estimates the chance of success is only about 20%.',
          body: 'How much would you invest if you had the opportunity today?',
        },
        slider: {
          context: {
            left: {
              text: {
                below: '0 Months Income',
              },
            },
            right: {
              text: {
                below: '12 Months Income',
              },
            },
          },
          default: '0.0',
          min: '0.0',
          max: '12.0',
          steps: {
            count: 12,
          },
          ticks: false,
        },
        icon: 'some_icon_here',
      },
      {
        question_number: 7,
        question_type: 'TREE',
        tree: {
          nodes: [
            //TODO: Need the full tree values for these nodes
            {
              node: 1,
              question_type: 'CARD',
              context: {
                body: 'Are you willing to lose 1/3rd of your annual income for a chance of doubling it?',
                left: {
                  text: {
                    center: 'No',
                  },
                  goto: {
                    node: 2,
                  },
                },
                right: {
                  text: {
                    center: 'Yes',
                  },
                  goto: {
                    node: 3,
                  },
                },
              },
              icon: 'some_icon_here',
            },
            {
              node: 2,
              question_type: 'CARD',
              context: {
                body: 'Are you willing to lose 1/5th of your annual income for a chance of doubling it?',
                left: {
                  text: {
                    center: 'No',
                  },
                  value: '1.0',
                },
                right: {
                  text: {
                    center: 'Yes',
                  },
                  value: '0.7',
                },
              },
              icon: 'some_icon_here',
            },
            {
              node: 2,
              question_type: 'CARD',
              context: {
                body: 'Are you willing to lose 1/2 of your annual income for a chance of doubling it?',
                left: {
                  text: {
                    center: 'No',
                  },
                  value: '0.3',
                },
                right: {
                  text: {
                    center: 'Yes',
                  },
                  value: '0.0',
                },
              },
              icon: 'some_icon_here',
            },
          ],
        },
      },
    ],
    [
      {
        question_number: 8,
        question_type: 'SLIDER',
        context: {
          body: 'Surprises rarely throw me off',
        },
        slider: {
          context: {
            left: {
              text: {
                below: 'Strongly Disagree',
              },
            },
            right: {
              text: {
                below: 'Strongly Agree',
              },
            },
          },
        },
        default: '4.5',
        min: '1.0',
        max: '7.0',
        steps: {
          count: 0,
        },
        ticks: false,
        icon: 'some_icon_here',
      },
      {
        question_number: 9,
        question_type: 'SLIDER',
        context: {
          body: 'I tend to get anxious when I don’t know the outcome.',
        },
        slider: {
          context: {
            left: {
              text: {
                below: 'Strongly Disagree',
              },
            },
            right: {
              text: {
                below: 'Strongly Agree',
              },
            },
          },
        },
        default: '4.5',
        min: '1.0',
        max: '7.0',
        steps: {
          count: 0,
        },
        ticks: false,
        icon: 'some_icon_here',
      },
      {
        question_number: 10,
        question_type: 'SLIDER',
        context: {
          body: 'Uncertainty doesn’t bother me as much as other people.',
        },
        slider: {
          context: {
            left: {
              text: {
                below: 'Strongly Disagree',
              },
            },
            right: {
              text: {
                below: 'Strongly Agree',
              },
            },
          },
        },
        default: '4.5',
        min: '1.0',
        max: '7.0',
        steps: {
          count: 0,
        },
        ticks: false,
        icon: 'some_icon_here',
      },
      {
        question_number: 11,
        question_type: 'SLIDER',
        context: {
          body: 'When I am uncertain, I can’t function very well.',
        },
        slider: {
          context: {
            left: {
              text: {
                below: 'Strongly Disagree',
              },
            },
            right: {
              text: {
                below: 'Strongly Agree',
              },
            },
          },
        },
        default: '4.5',
        min: '1.0',
        max: '7.0',
        steps: {
          count: 0,
        },
        ticks: false,
        icon: 'some_icon_here',
      },
      {
        question_number: 12,
        question_type: 'MULTI_CARD',
        context: {
          body: 'When thinking about retirement, what concerns you the most? [Select up to 3]',
        },
        cards: [
          ['Running out of money', '0.0'],
          ['Market Volatility', '0.0'],
          ['Inflation', '0.0'],
          ['Not prepared enough for it', '0.0'],
          ['Rising medical expenses/ Unexpected expenses', '0.0'],
          ['Becoming debt-free/Paying-off my debt', '0.0'],
        ],
        icon: 'some_icon_here',
      },
      {
        question_number: 13,
        question_type: 'MULTI_CARD',
        context: {
          body: 'When thinking about retirement, what do you look for the most? [Select up to 3]',
        },
        cards: [
          ['Volunteering my time', '0.0'],
          ['Spending more time with family and/or friends', '0.0'],
          ['Traveling the world', '0.0'],
          ['Starting a new hobby', '0.0'],
          ['Joining a community organization', '0.0'],
          ['Taking educational courses', '0.0'],
          ['Getting a part-time job', '0.0'],
        ],
        icon: 'some_icon_here',
      },
      {
        question_number: 14,
        question_type: 'MULTI_SLIDER',
        context: {
          body: 'How do you feel about your retirement planning?',
        },
        sliders: [
          {
            context: {
              left: {
                text: {
                  below: 'Interesting',
                },
              },
              right: {
                text: {
                  below: 'Uggh, Boring',
                },
              },
            },
            default: '0.5',
            min: '0.0',
            max: '1.0',
            steps: {
              count: 0,
            },
            ticks: false,
          },
          {
            context: {
              left: {
                text: {
                  below: 'Prefer to be hands on',
                },
              },
              right: {
                text: {
                  below: 'Prefer an expert to handle it',
                },
              },
            },
            default: '0.5',
            min: '0.0',
            max: '1.0',
            steps: {
              count: 0,
            },
            ticks: false,
          },
        ],
        icon: 'some_icon_here',
      },
      {
        question_number: 15,
        question_type: 'MULTI_INPUT',
        context: {
          body: 'We need some Demographic Information...',
        },
        inputs: [
          {
            context: {
              body: 'What is your first name?',
            },
          },
          {
            context: {
              body: 'How young are you?',
            },
          },
        ],
        icon: 'some_icon_here',
      },
    ],
  ],
  responses: [[]],
  status: 'Created',
  percent_complete: 0,
  last_question_completed: 0,
  total_number_of_questions: 15,
  completed_at: '',
  created_at: '2020-01-01T00:00:00.000Z',
  updated_at: '2020-01-01T00:00:00.000Z',
  meta_data: {
    test_key: 'test_value',
  },
};
export default API_RESPONSE_DATA;

export {ASSESSMENT_POST_API_URL, CDN_URL};
