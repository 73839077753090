import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
// Import logger from "redux-logger";
import {rootReducer} from './reducers';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export default function configureStore() {
  return createStore(
    rootReducer,
    compose(applyMiddleware(thunk), sentryReduxEnhancer)
  );
}
