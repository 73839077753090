function between(x: number, min: number, max: number) {
  return x >= min && x <= max;
}

const question2Checker = (choice: string) => {
  if (choice === '$5 million Today') {
    return '1.0';
  }
  return '0.0';
};

const question3Checker = (choice: string) => {
  if (choice === 'Over in the blink of an eye') {
    return '0.0';
  } else if (choice === 'Goes by pretty quickly') {
    return '0.3';
  } else if (choice === 'It does seem long') {
    return '0.8';
  }
  return '1.0';
};

const question4Checker = (choice: string) => {
  const numberChoice = 1 - parseInt(choice, 10) / 100;
  return numberChoice.toString();
};

const question5Checker = (choice: string) => {
  let numberChoice = parseInt(choice, 10) / 10;
  if (between(numberChoice, 0, 0.19)) {
    numberChoice = 1.0;
  } else if (between(numberChoice, 0.2, 0.39)) {
    numberChoice = 0.75;
  } else if (between(numberChoice, 0.4, 0.59)) {
    numberChoice = 0.5;
  } else if (between(numberChoice, 0.6, 0.79)) {
    numberChoice = 0.25;
  } else if (between(numberChoice, 0.8, 1.0)) {
    numberChoice = 0.0;
  }
  const payload = {
    value: '',
    score: numberChoice.toString(),
  };
  if (numberChoice >= 0.5) {
    payload.value = 'Definitely more job security with a small pay increase';
  } else {
    payload.value = 'Definitely less job security with a big pay increase';
  }
  return payload;
};
const question7Checker = (choice1: string, choice2: string) => {
  if (`${choice1}-${choice2}` === 'Yes-Yes') {
    return {
      value: 'Yes-Yes',
      score: '0.0',
    };
  } else if (`${choice1}-${choice2}` === 'Yes-No') {
    return {
      value: 'Yes-No',
      score: '0.3',
    };
  } else if (`${choice1}-${choice2}` === 'No-Yes') {
    return {
      value: 'No-Yes',
      score: '0.7',
    };
  } else if (`${choice1}-${choice2}` === 'No-No') {
    return {
      value: 'No-No',
      score: '1.0',
    };
  } else {
    return {
      value: 'N/A',
      score: '0.0',
    };
  }
};

const question8_9_10_11Checker = (choice: string) => {
  const numberChoice = parseInt(choice, 10) / 10;
  return Math.round(numberChoice).toString();
};
const question13Checker = (choice: string) => {
  const numberChoice = parseInt(choice, 10) / 100;
  return numberChoice.toString();
};

const PutDataRearrange = (preData: string[][], type: string) => {
  const returnData: {
    question_number: number;
    responses: {value: string; score: string}[];
  }[] = [];
  if (preData[0][1]) {
    returnData[0] = {
      question_number: 1,
      responses: [
        {
          value: preData[0][0],
          score: preData[0][0],
        },
        {
          value: preData[0][1],
          score: preData[0][1],
        },
      ],
    };
  }

  if (preData[1][0]) {
    returnData[1] = {
      question_number: 2,
      responses: [
        {
          value: preData[1][0] as string,
          score: question2Checker(preData[1][0] as string),
        },
      ],
    };
  }

  if (preData[2][0]) {
    returnData[2] = {
      question_number: 3,
      responses: [
        {
          value: preData[2][0] as string,
          score: question3Checker(preData[2][0] as string),
        },
      ],
    };
  }

  if (preData[3][0]) {
    returnData[3] = {
      question_number: 4,
      responses: [
        {
          value: question4Checker(preData[3][0]),
          score: question4Checker(preData[3][0]),
        },
      ],
    };
  }

  if (preData[4][0]) {
    returnData[4] = {
      question_number: 5,
      responses: [
        {
          ...question5Checker(preData[4][0]),
        },
      ],
    };
  }
  if (preData[5][0]) {
    returnData[5] = {
      question_number: 6,
      responses: [
        {
          value: preData[5][0],
          score: preData[5][0],
        },
      ],
    };
  }
  if (preData[6][1]) {
    returnData[6] = {
      question_number: 7,
      responses: [
        {
          value: question7Checker(preData[6][0], preData[6][1]).value,
          score: question7Checker(preData[6][0], preData[6][1]).score,
        },
      ],
    };
  }
  if (preData[7][0]) {
    returnData[7] = {
      question_number: 8,
      responses: [
        {
          value: question8_9_10_11Checker(preData[7][0]),
          score: question8_9_10_11Checker(preData[7][0]),
        },
      ],
    };
  }
  if (preData[8][0]) {
    returnData[8] = {
      question_number: 9,
      responses: [
        {
          value: question8_9_10_11Checker(preData[8][0]),
          score: question8_9_10_11Checker(preData[8][0]),
        },
      ],
    };
  }
  if (preData[9][0]) {
    returnData[9] = {
      question_number: 10,
      responses: [
        {
          value: question8_9_10_11Checker(preData[9][0]),
          score: question8_9_10_11Checker(preData[9][0]),
        },
      ],
    };
  }
  if (preData[10][0]) {
    returnData[10] = {
      question_number: 11,
      responses: [
        {
          value: question8_9_10_11Checker(preData[10][0]),
          score: question8_9_10_11Checker(preData[10][0]),
        },
      ],
    };
  }
  if (preData[11][0]) {
    returnData[11] = {
      question_number: 12,
      responses: [
        {
          value: preData[11][0],
          score: '0.0',
        },
      ],
    };
    if (preData[11][1]) {
      returnData[11] = {
        question_number: 12,
        responses: [
          {
            value: preData[11][0],
            score: '0.0',
          },
          {
            value: preData[11][1],
            score: '0.0',
          },
        ],
      };
    }
    if (preData[11][2]) {
      returnData[11] = {
        question_number: 12,
        responses: [
          {
            value: preData[11][0],
            score: '0.0',
          },
          {
            value: preData[11][1],
            score: '0.0',
          },
          {
            value: preData[11][2],
            score: '0.0',
          },
        ],
      };
    }
  }
  if (preData[12][0]) {
    returnData[12] = {
      question_number: 13,
      responses: [
        {
          value: preData[12][0],
          score: '0.0',
        },
      ],
    };
    if (preData[12][1]) {
      returnData[12] = {
        question_number: 13,
        responses: [
          {
            value: preData[12][0],
            score: '0.0',
          },
          {
            value: preData[12][1],
            score: '0.0',
          },
        ],
      };
    }
    if (preData[12][2]) {
      returnData[12] = {
        question_number: 13,
        responses: [
          {
            value: preData[12][0],
            score: '0.0',
          },
          {
            value: preData[12][1],
            score: '0.0',
          },
          {
            value: preData[12][2],
            score: '0.0',
          },
        ],
      };
    }
  }
  if (preData[13][0]) {
    returnData[13] = {
      question_number: 14,
      responses: [
        {
          value: question13Checker(preData[13][0]),
          score: question13Checker(preData[13][0]),
        },
        {
          value: question13Checker(preData[13][1]),
          score: question13Checker(preData[13][1]),
        },
      ],
    };
  }
  if (preData[14][0] && type === 'put') {
    returnData[14] = {
      question_number: 15,
      responses: [
        {
          value: preData[14][0],
          score: '0.0',
        },
        {
          value: preData[14][1],
          score: preData[14][1],
        },
      ],
    };
  }
  return returnData;
};

export default PutDataRearrange;
