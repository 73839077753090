import {
  Flex,
  Image,
  Progress,
  Text,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import './styles/styles.css';
import Section2Background from '../../Assets/Report-Section2-BgTrail-blur.svg';
import ChevronRight from '../../Assets/Slider-Chevron-Right.svg';
import AccordionExtra from '../../Assets/Report-Section3-Accordion-Extra.svg';
import AccordionExtra2 from '../../Assets/Report-AccordionExtra2.svg';
import ChevronUpDown from '../../Assets/Report-Section4-Chevron-Down.svg';
import BiometricPicture from '../../Assets/Report-Section3-Biometric2.svg';
import ProfilePictureBlue from '../../Assets/Report-Section3-ProfileBlue.svg';
import VideoPlay from '../../Assets/Report-Section3-Video.svg';
import SyntoniqLogo from '../../Assets/Syntoniq-logo.svg';
import {useMediaQuery} from 'react-responsive';
import MobileReport from './MobileReport';
import {useParams} from 'react-router-dom';
import {ReportData, ResponseData} from '../../type2';
import ExampleReport from './exampleReport';
import axios from 'axios';
import Section1Bg from '../Section1Image';
import {ASSESSMENT_POST_API_URL} from '../../Data';
import Section2Bullet from '../../Assets/Mobile-Report-Section2-Bullet.svg';
import {ScrollingProvider, Section} from 'react-scroll-section';
import AorAn from '../AorAn';
import {Box} from '@chakra-ui/layout';

type ReportState = {
  fetched: boolean;
  data: ReportData;
  message: string;
};

interface Props {
  reportData: ReportData;
  currentTab: string;
}

const Section4Accordion: React.FC<Props> = ({reportData, currentTab}) => {
  const [selectedAccordion, setSelectedAccordion] = useState(0);
  useEffect(() => {
    setSelectedAccordion(0);
  }, [currentTab]);
  if (currentTab === 'Future Focus') {
    return (
      <Flex className="Report-Section4-SingleTab" mt="72px">
        <Flex flex="2" direction="column">
          <Box
            aria-label={`Future Focus ${reportData.report.scores.focus.value} out of 100`}
          >
            <Text className="Report-Section4-SingleTab-Heading">
              Future Focus
            </Text>
            <div className="Report-Section4-SingleTab-Score">
              {reportData.report.scores.focus.value}
              <VisuallyHidden>Out of 100</VisuallyHidden>
              <p className="Report-Section4-SingleTab-100" aria-hidden={true}>
                {' '}
                / 100
              </p>
            </div>
          </Box>
        </Flex>
        <Flex flex="5" direction="column">
          <Flex
            className="Report-Section4-Accordion"
            borderBottom={selectedAccordion === 0 ? '' : '1px solid #c4c4c4'}
            onClick={() => {
              setSelectedAccordion(0);
            }}
            role="button"
            tabIndex={0}
            aria-expanded="true"
            id="one"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || 'Space') {
                setSelectedAccordion(0);
                e.currentTarget.setAttribute('aria-expanded', String(true));
                document
                  .getElementById('two')
                  ?.setAttribute('aria-expanded', String(false));
                document
                  .getElementById('three')
                  ?.setAttribute('aria-expanded', String(false));
              }
            }}
          >
            <Text className="Report-Section4-Accordion-Text">
              {reportData.report.scores.focus.what_it_means.title}
            </Text>
            <Image
              src={ChevronUpDown}
              transform={
                selectedAccordion === 0 ? 'rotate(180deg)' : 'rotate(0deg)'
              }
            />
          </Flex>

          {selectedAccordion === 0 && (
            <Flex>
              <Text className="Report-Section4-Accordion-Open-Text">
                {reportData.report.scores.focus.what_it_means.body}
              </Text>
            </Flex>
          )}
          <Flex
            className="Report-Section4-Accordion"
            borderBottom={selectedAccordion === 1 ? '' : '1px solid #c4c4c4'}
            onClick={() => {
              setSelectedAccordion(1);
            }}
            role="button"
            tabIndex={0}
            aria-expanded="false"
            id="two"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || 'Space') {
                setSelectedAccordion(1);
                e.currentTarget.setAttribute('aria-expanded', String(true));
                document
                  .getElementById('one')
                  ?.setAttribute('aria-expanded', String(false));
                document
                  .getElementById('three')
                  ?.setAttribute('aria-expanded', String(false));
              }
            }}
          >
            <Text className="Report-Section4-Accordion-Text">
              {reportData.report.scores.focus.what_is_awesome.title}
            </Text>
            <Image
              src={ChevronUpDown}
              transform={
                selectedAccordion === 1 ? 'rotate(180deg)' : 'rotate(0deg)'
              }
            />
          </Flex>

          {selectedAccordion === 1 && (
            <Flex>
              <Text className="Report-Section4-Accordion-Open-Text">
                {reportData.report.scores.focus.what_is_awesome.body}
              </Text>
            </Flex>
          )}
          <Flex
            className="Report-Section4-Accordion"
            borderBottom={selectedAccordion === 2 ? '' : '1px solid #c4c4c4'}
            onClick={() => {
              setSelectedAccordion(2);
            }}
            role="button"
            tabIndex={0}
            aria-expanded="false"
            id="three"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || 'Space') {
                setSelectedAccordion(2);
                e.currentTarget.setAttribute('aria-expanded', String(true));
                document
                  .getElementById('one')
                  ?.setAttribute('aria-expanded', String(false));
                document
                  .getElementById('two')
                  ?.setAttribute('aria-expanded', String(false));
              }
            }}
          >
            <Text className="Report-Section4-Accordion-Text">
              {reportData.report.scores.focus.what_to_watchout_for.title}
            </Text>
            <Image
              src={ChevronUpDown}
              transform={
                selectedAccordion === 2 ? 'rotate(180deg)' : 'rotate(0deg)'
              }
            />
          </Flex>
          {selectedAccordion === 2 && (
            <Flex>
              <Text className="Report-Section4-Accordion-Open-Text">
                {reportData.report.scores.focus.what_to_watchout_for.body}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  } else if (currentTab === 'Desire for Certainty') {
    return (
      <Flex className="Report-Section4-SingleTab" mt="72px">
        <Flex flex="2" direction="column">
          <Box
            aria-label={`Desire for Certainty ${reportData.report.scores.certainty.value} out of 100`}
          >
            <Text className="Report-Section4-SingleTab-Heading">
              Desire for Certainty
            </Text>
            <div className="Report-Section4-SingleTab-Score">
              {reportData.report.scores.certainty.value}
              <VisuallyHidden>Out of 100</VisuallyHidden>
              <p className="Report-Section4-SingleTab-100" aria-hidden={true}>
                {' '}
                / 100
              </p>
            </div>
          </Box>
        </Flex>
        <Flex flex="5" direction="column">
          <Flex
            className="Report-Section4-Accordion"
            borderBottom={selectedAccordion === 0 ? '' : '1px solid #c4c4c4'}
            onClick={() => {
              setSelectedAccordion(0);
            }}
            role="button"
            tabIndex={0}
            aria-expanded="true"
            id="one"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || 'Space') {
                setSelectedAccordion(0);
                e.currentTarget.setAttribute('aria-expanded', String(true));
                document
                  .getElementById('two')
                  ?.setAttribute('aria-expanded', String(false));
                document
                  .getElementById('three')
                  ?.setAttribute('aria-expanded', String(false));
              }
            }}
          >
            <Text className="Report-Section4-Accordion-Text">
              {reportData.report.scores.certainty.what_it_means.title}
            </Text>
            <Image
              src={ChevronUpDown}
              transform={
                selectedAccordion === 0 ? 'rotate(180deg)' : 'rotate(0deg)'
              }
            />
          </Flex>

          {selectedAccordion === 0 && (
            <Flex>
              <Text className="Report-Section4-Accordion-Open-Text">
                {reportData.report.scores.certainty.what_it_means.body}
              </Text>
            </Flex>
          )}
          <Flex
            className="Report-Section4-Accordion"
            borderBottom={selectedAccordion === 1 ? '' : '1px solid #c4c4c4'}
            onClick={() => {
              setSelectedAccordion(1);
            }}
            role="button"
            tabIndex={0}
            aria-expanded="false"
            id="two"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || 'Space') {
                setSelectedAccordion(1);
                e.currentTarget.setAttribute('aria-expanded', String(true));
                document
                  .getElementById('one')
                  ?.setAttribute('aria-expanded', String(false));
                document
                  .getElementById('three')
                  ?.setAttribute('aria-expanded', String(false));
              }
            }}
          >
            <Text className="Report-Section4-Accordion-Text">
              {reportData.report.scores.certainty.what_is_awesome.title}
            </Text>
            <Image
              src={ChevronUpDown}
              alt="open/close"
              transform={
                selectedAccordion === 1 ? 'rotate(180deg)' : 'rotate(0deg)'
              }
            />
          </Flex>

          {selectedAccordion === 1 && (
            <Flex>
              <Text className="Report-Section4-Accordion-Open-Text">
                {reportData.report.scores.certainty.what_is_awesome.body}
              </Text>
            </Flex>
          )}
          <Flex
            className="Report-Section4-Accordion"
            borderBottom={selectedAccordion === 2 ? '' : '1px solid #c4c4c4'}
            onClick={() => {
              setSelectedAccordion(2);
            }}
            role="button"
            tabIndex={0}
            aria-expanded="false"
            id="three"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || 'Space') {
                setSelectedAccordion(2);
                e.currentTarget.setAttribute('aria-expanded', String(true));
                document
                  .getElementById('one')
                  ?.setAttribute('aria-expanded', String(false));
                document
                  .getElementById('two')
                  ?.setAttribute('aria-expanded', String(false));
              }
            }}
          >
            <Text className="Report-Section4-Accordion-Text">
              {reportData.report.scores.certainty.what_to_watchout_for.title}
            </Text>
            <Image
              src={ChevronUpDown}
              alt="open/close"
              transform={
                selectedAccordion === 2 ? 'rotate(180deg)' : 'rotate(0deg)'
              }
            />
          </Flex>
          {selectedAccordion === 2 && (
            <Flex>
              <Text className="Report-Section4-Accordion-Open-Text">
                {reportData.report.scores.certainty.what_to_watchout_for.body}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex className="Report-Section4-SingleTab" mt="72px">
      <Flex flex="2" direction="column">
        <Box
          aria-label={`Tendency to Avoid Risk ${reportData.report.scores.certainty.value} out of 100`}
        >
          <Text className="Report-Section4-SingleTab-Heading">
            {`Tendency to\nAvoid Risk`}
          </Text>
          <div className="Report-Section4-SingleTab-Score">
            {reportData.report.scores.risk.value}
            <VisuallyHidden>Out of 100</VisuallyHidden>
            <p className="Report-Section4-SingleTab-100" aria-hidden={true}>
              {' '}
              / 100
            </p>
          </div>
        </Box>
      </Flex>
      <Flex flex="5" direction="column">
        <Flex
          className="Report-Section4-Accordion"
          borderBottom={selectedAccordion === 0 ? '' : '1px solid #c4c4c4'}
          onClick={() => {
            setSelectedAccordion(0);
          }}
          role="button"
          tabIndex={0}
          aria-expanded="true"
          id="one"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || 'Space') {
              setSelectedAccordion(0);
              e.currentTarget.setAttribute('aria-expanded', String(true));
              document
                .getElementById('two')
                ?.setAttribute('aria-expanded', String(false));
              document
                .getElementById('three')
                ?.setAttribute('aria-expanded', String(false));
            }
          }}
        >
          <Text className="Report-Section4-Accordion-Text">
            {reportData.report.scores.risk.what_it_means.title}
          </Text>
          <Image
            src={ChevronUpDown}
            transform={
              selectedAccordion === 0 ? 'rotate(180deg)' : 'rotate(0deg)'
            }
          />
        </Flex>

        {selectedAccordion === 0 && (
          <Flex>
            <Text className="Report-Section4-Accordion-Open-Text">
              {reportData.report.scores.risk.what_it_means.body}
            </Text>
          </Flex>
        )}
        <Flex
          className="Report-Section4-Accordion"
          borderBottom={selectedAccordion === 1 ? '' : '1px solid #c4c4c4'}
          onClick={() => {
            setSelectedAccordion(1);
          }}
          role="button"
          tabIndex={0}
          aria-expanded="false"
          id="two"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || 'Space') {
              setSelectedAccordion(1);
              e.currentTarget.setAttribute('aria-expanded', String(true));
              document
                .getElementById('one')
                ?.setAttribute('aria-expanded', String(false));
              document
                .getElementById('three')
                ?.setAttribute('aria-expanded', String(false));
            }
          }}
        >
          <Text className="Report-Section4-Accordion-Text">
            {reportData.report.scores.risk.what_is_awesome.title}
          </Text>
          <Image
            src={ChevronUpDown}
            alt="open/close"
            transform={
              selectedAccordion === 1 ? 'rotate(180deg)' : 'rotate(0deg)'
            }
          />
        </Flex>

        {selectedAccordion === 1 && (
          <Flex>
            <Text className="Report-Section4-Accordion-Open-Text">
              {reportData.report.scores.risk.what_is_awesome.body}
            </Text>
          </Flex>
        )}
        <Flex
          className="Report-Section4-Accordion"
          borderBottom={selectedAccordion === 2 ? '' : '1px solid #c4c4c4'}
          onClick={() => {
            setSelectedAccordion(2);
          }}
          role="button"
          tabIndex={0}
          aria-expanded="false"
          id="three"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || 'Space') {
              setSelectedAccordion(2);
              e.currentTarget.setAttribute('aria-expanded', String(true));
              document
                .getElementById('one')
                ?.setAttribute('aria-expanded', String(false));
              document
                .getElementById('two')
                ?.setAttribute('aria-expanded', String(false));
            }
          }}
        >
          <Text className="Report-Section4-Accordion-Text">
            {reportData.report.scores.risk.what_to_watchout_for.title}
          </Text>
          <Image
            src={ChevronUpDown}
            alt="open/close"
            transform={
              selectedAccordion === 2 ? 'rotate(180deg)' : 'rotate(0deg)'
            }
          />
        </Flex>
        {selectedAccordion === 2 && (
          <Flex>
            <Text className="Report-Section4-Accordion-Open-Text">
              {reportData.report.scores.risk.what_to_watchout_for.body}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const Report: React.FC = () => {
  const [reportData, setReportData] = useState<ReportState>({
    fetched: false,
    data: ExampleReport,
    message: '',
  });
  const toast = useToast();
  const DetailsRef = useRef<HTMLDivElement>(null);
  const {assessmentID} = useParams<{assessmentID: string}>();
  const [section4Tab, setSection4Tab] = useState('Future Focus');
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1000px)'});
  const [section2Accordion, setSection2Accordion] = useState(() => {
    if (
      reportData.data.report.take_action.advice_first ||
      (reportData.data.report.take_action.advice_first === false &&
        reportData.data.report.take_action.education_first === false)
    ) {
      return 0;
    }
    return 0;
  });
  useEffect(() => {
    if (
      reportData.data.report.take_action.advice_first ||
      (reportData.data.report.take_action.advice_first === false &&
        reportData.data.report.take_action.education_first === false)
    ) {
      setSection2Accordion(0);
    } else {
      setSection2Accordion(1);
    }
  }, [
    reportData.data.report.take_action.advice_first,
    reportData.data.report.take_action.education_first,
    reportData.fetched,
  ]);
  const [hoveredOver, setHoveredOver] = useState(false);
  // const is12Inch = useMediaQuery({ query: "(max-width: 1200px)" });
  const LinkClick = (url: string) => {
    const payload = {
      url: url,
    };
    const PostingUrl = ASSESSMENT_POST_API_URL + `/${assessmentID}/ctas`;
    axios
      .post(PostingUrl, payload)
      .then(() => {})
      .catch((error) => {
        console.warn('Error during posting to CTAs', error);
      });
  };

  useEffect(() => {
    const triggerWarningToast = () => {
      toast({
        title: `Please complete your assessment to see the results`,
        status: 'warning',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    };
    const trigger500Toast = () => {
      toast({
        title: `Hmmm... Somethings not quite right on our end.`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    };
    axios
      .get<ResponseData>(ASSESSMENT_POST_API_URL + `/${assessmentID}/report`)
      .then((response) => {
        if (
          response?.status === 200 &&
          !(response?.data?.code === '428' || response?.data?.code === '404')
        ) {
          if (response?.data.client && response?.data.report) {
            setReportData({
              fetched: true,
              data: response?.data as ReportData,
              message: 'Success',
            });
          }
        } else {
          triggerWarningToast();
          setReportData((prev) => {
            console.warn(
              `Error ${response?.data?.code}`,
              response?.data?.error
            );
            return {
              fetched: false,
              data: prev.data,
              message: response?.data?.error as string,
            };
          });
        }
      })
      .catch((error) => {
        setReportData((prev) => {
          if (error?.response?.status === 500) {
            console.warn('Error 500', error);
            trigger500Toast();
            return {
              fetched: false,
              data: prev.data,
              message: 'There was a problem in fetching the report.',
            };
          }
          console.warn('Error 404', error);
          triggerWarningToast();
          return {
            fetched: false,
            data: prev.data,
            message: '404. Report Page Not Found.',
          };
        });
      });
  }, [assessmentID, toast]);
  return reportData.fetched ? (
    isTabletOrMobile ? (
      <MobileReport reportData={reportData.data} assessmentID={assessmentID} />
    ) : (
      <ScrollingProvider>
        <Flex direction="column" className="Report-Container">
          <Flex className="Report-Section1" direction="column">
            <Flex
              className="Report-Section1-Bg-Image"
              bgImage={Section1Bg(reportData.data.report.header_image, 'web')}
            >
              <Image
                w="100%"
                height="100%"
                src={Section1Bg(reportData.data.report.header_image, 'web')}
                alt="Background"
                visibility="hidden"
              />
            </Flex>
            <Flex className="Report-Section1-Top">
              <Flex
                direction="column"
                className="Report-Section1-LeftColumn"
                flex="1"
              >
                <Flex direction="column">
                  <Text
                    _focus={{
                      boxShadow: '0 0 0 3px #ED00B9',
                    }}
                    className="Report-Section1-Heading-Pre"
                  >
                    <b>{reportData.data.client.first_name}</b>, You are{' '}
                    {` ${AorAn(reportData.data.report.scores.persona)}`}
                  </Text>
                  <Text
                    _focus={{
                      boxShadow: '0 0 0 3px #ED00B9',
                    }}
                    className="Report-Section1-Heading"
                  >
                    {reportData.data.report.scores.persona}
                  </Text>
                  <Text
                    _focus={{
                      boxShadow: '0 0 0 3px #ED00B9',
                    }}
                    className="Report-Section1-Heading-Post"
                  >
                    {reportData.data.report.scores.persona_description}
                  </Text>
                  <div className="Report-Section1-Divider"></div>
                </Flex>

                <Flex direction="column">
                  <Text
                    _focus={{
                      boxShadow: '0 0 0 3px #ED00B9',
                    }}
                    className="Report-Section1-SubHeading-Title"
                  >
                    ABOUT THIS REPORT
                  </Text>
                  <Text
                    _focus={{
                      boxShadow: '0 0 0 3px #ED00B9',
                    }}
                    className="Report-Section1-SubHeading"
                  >
                    Our Behavior Personality Report is designed to help you
                    uncover the key psychological factors that can influence
                    your financial decision-making process.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              className="Report-Section1-Bottom"
              direction="column"
              w="100%"
            >
              <Flex w="100%" justifyContent="space-between" alignItems="center">
                <Text
                  _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                  className="Report-Section1-Bottom-Text"
                >
                  Factors influencing your financial decision-making
                </Text>
              </Flex>
              <Flex justifyContent="space-between" mt="20px">
                <Flex
                  aria-label={`Future Focus ${reportData.data.report.scores.focus.value} out of 100`}
                  _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                  className="Report-Section-Bottom-Card"
                >
                  <Text className="Report-Section-Bottom-Card-Text">
                    Future Focus
                  </Text>
                  <Flex direction="column">
                    <div className="Report-Section-Bottom-Card-Progress">
                      {reportData.data.report.scores.focus.value}
                      <VisuallyHidden>Out of 100</VisuallyHidden>
                      <p
                        className="Report-Section-Bottom-Card-Progress-100"
                        aria-hidden={true}
                      >
                        {' '}
                        / 100
                      </p>
                    </div>
                    <Progress
                      value={reportData.data.report.scores.focus.value}
                      maxW="300px"
                      className="Report-Progress1"
                      background="#004C95"
                      borderRadius="100px"
                      aria-hidden={true}
                    />
                  </Flex>
                </Flex>
                <Flex
                  aria-label={`Tendency to Avoid Risk ${reportData.data.report.scores.risk.value} out of 100`}
                  _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                  className="Report-Section-Bottom-Card"
                >
                  <Text className="Report-Section-Bottom-Card-Text">
                    Tendency to Avoid Risk
                  </Text>
                  <Flex direction="column">
                    <div className="Report-Section-Bottom-Card-Progress">
                      {reportData.data.report.scores.risk.value}
                      <VisuallyHidden>Out of 100</VisuallyHidden>
                      <p
                        className="Report-Section-Bottom-Card-Progress-100"
                        aria-hidden={true}
                      >
                        {' '}
                        / 100
                      </p>
                    </div>
                    <Progress
                      className="Report-Progress2"
                      value={reportData.data.report.scores.risk.value}
                      maxW="300px"
                      background="#004C95"
                      borderRadius="100px"
                      aria-hidden={true}
                    />
                  </Flex>
                </Flex>
                <Flex
                  aria-label={`Desire for Certainty ${reportData.data.report.scores.risk.value} out of 100`}
                  _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                  className="Report-Section-Bottom-Card"
                >
                  <Text className="Report-Section-Bottom-Card-Text">
                    Desire for Certainty
                  </Text>
                  <Flex direction="column">
                    <div className="Report-Section-Bottom-Card-Progress">
                      {reportData.data.report.scores.certainty.value}
                      <VisuallyHidden>Out of 100</VisuallyHidden>
                      <p
                        className="Report-Section-Bottom-Card-Progress-100"
                        aria-hidden={true}
                      >
                        {' '}
                        / 100
                      </p>
                    </div>
                    <Progress
                      className="Report-Progress3"
                      value={reportData.data.report.scores.certainty.value}
                      maxW="300px"
                      background="#004C95"
                      borderRadius="100px"
                      aria-hidden={true}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
              className="Report-Section1-Bottom-Button"
              onClick={() => {
                if (DetailsRef != null && DetailsRef.current != null) {
                  DetailsRef.current.scrollIntoView();
                }
              }}
            >
              <div className="container">
                <div className="chevron"></div>
                <div className="chevron"></div>
                <div className="chevron"></div>
              </div>
            </Flex>
          </Flex>
          <Flex direction="column" className="Report-Section2" ref={DetailsRef}>
            <Flex className="Report-Section2-Arrows">
              <Image src={Section2Background} alt="Trail" maxW="100%" />
            </Flex>
            <Text
              _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
              className="Report-Section2-Heading"
            >
              Your Journey
            </Text>
            <Flex className="Report-Section2-Box1">
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section2-Box-Heading"
              >
                {reportData.data.report.your_journey[0].title}
              </Text>
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section2-Box-Para"
              >
                {reportData.data.report.your_journey[0].subtitle}
              </Text>
              <Flex direction="column">
                {reportData.data.report.your_journey[0].bullets.map(
                  (bulletPoint, index) => {
                    return (
                      <Flex
                        _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                        mt="16px"
                        key={index}
                      >
                        <Image
                          w="12px"
                          h="12px"
                          mt="7px"
                          mr="8px"
                          src={Section2Bullet}
                          alt="Bullet point"
                        />
                        <Text className="Report-Section2-Box-Bullet">
                          {bulletPoint}
                        </Text>
                      </Flex>
                    );
                  }
                )}
              </Flex>
            </Flex>

            <Flex className="Report-Section2-Box2">
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section2-Box-Heading"
              >
                {reportData.data.report.your_journey[1].title}
              </Text>
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section2-Box-Para"
              >
                {reportData.data.report.your_journey[1].subtitle}
              </Text>
              <Flex direction="column">
                {reportData.data.report.your_journey[1].bullets.map(
                  (bulletPoint, index) => {
                    return (
                      <Flex
                        _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                        mt="16px"
                        key={index}
                      >
                        <Image
                          w="12px"
                          h="12px"
                          mt="7px"
                          mr="8px"
                          src={Section2Bullet}
                          alt="Bullet point"
                        />
                        <Text className="Report-Section2-Box-Bullet">
                          {bulletPoint}
                        </Text>
                      </Flex>
                    );
                  }
                )}
              </Flex>
            </Flex>

            <Flex className="Report-Section2-Box3">
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section2-Box-Heading"
              >
                {reportData.data.report.your_journey[2].title}
              </Text>
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section2-Box-Para"
              >
                {reportData.data.report.your_journey[2].subtitle}
              </Text>
              <Flex direction="column">
                {reportData.data.report.your_journey[2].bullets.map(
                  (bulletPoint, index) => {
                    return (
                      <Flex
                        _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                        mt="16px"
                        key={index}
                      >
                        <Image
                          w="12px"
                          h="12px"
                          mt="7px"
                          mr="8px"
                          src={Section2Bullet}
                          alt="Bullet point"
                        />
                        <Text className="Report-Section2-Box-Bullet">
                          {bulletPoint}
                        </Text>
                      </Flex>
                    );
                  }
                )}
              </Flex>
            </Flex>

            <Flex className="Report-Section2-Box4">
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section2-Box-Heading"
              >
                {reportData.data.report.your_journey[3].title}
              </Text>
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section2-Box-Para"
              >
                {reportData.data.report.your_journey[3].subtitle}
              </Text>
              <Flex direction="column">
                {reportData.data.report.your_journey[3].bullets.map(
                  (bulletPoint, index) => {
                    return (
                      <Flex
                        _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                        mt="16px"
                        key={index}
                      >
                        <Image
                          w="12px"
                          h="12px"
                          mt="7px"
                          mr="8px"
                          src={Section2Bullet}
                          alt="Bullet point"
                        />
                        <Text className="Report-Section2-Box-Bullet">
                          {bulletPoint}
                        </Text>
                      </Flex>
                    );
                  }
                )}
              </Flex>
            </Flex>
          </Flex>

          <Flex className="Report-Section3">
            <Flex flex="3" direction="column">
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section3-Heading"
                mt="24px"
              >
                START HERE
              </Text>
              <Text
                _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                className="Report-Section3-Heading-Post"
              >
                Choose one of the following below:
              </Text>
              <Flex className="Report-Section3-Accordion" role="radiogroup">
                {reportData.data.report.take_action.advice_first ? (
                  <>
                    <Flex
                      _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                      className={
                        section2Accordion === 0
                          ? 'Report-Section3-Accordion-Selected'
                          : 'Report-Section3-Accordion-UnSelected'
                      }
                      onClick={() => {
                        setSection2Accordion(0);
                      }}
                      id="guidance"
                      tabIndex={0}
                      role="radio"
                      aria-checked="true"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || 'Space') {
                          setSection2Accordion(0);
                          e.currentTarget.setAttribute(
                            'aria-checked',
                            String(true)
                          );
                          document
                            .getElementById('learn')
                            ?.setAttribute('aria-checked', String(false));
                        }
                      }}
                    >
                      <Flex
                        className={
                          section2Accordion === 0
                            ? 'Report-Section3-Accordion-Selected-circle'
                            : 'Report-Section3-Accordion-UnSelected-circle'
                        }
                      >
                        1
                      </Flex>
                      <Text className="Report-Section3-Accordion-Selected-Text">
                        Get Guidance
                      </Text>
                      <Image ml="auto" mr="26px" w="40px" src={ChevronRight} />
                    </Flex>

                    <Flex
                      _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                      className={
                        section2Accordion === 1
                          ? 'Report-Section3-Accordion-Selected'
                          : 'Report-Section3-Accordion-UnSelected'
                      }
                      onClick={() => {
                        setSection2Accordion(1);
                      }}
                      id="learn"
                      tabIndex={0}
                      role="radio"
                      aria-checked="false"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || 'Space') {
                          setSection2Accordion(1);
                          e.currentTarget.setAttribute(
                            'aria-checked',
                            String(true)
                          );
                          document
                            .getElementById('guidance')
                            ?.setAttribute('aria-checked', String(false));
                        }
                      }}
                    >
                      <Flex
                        className={
                          section2Accordion === 1
                            ? 'Report-Section3-Accordion-Selected-circle'
                            : 'Report-Section3-Accordion-UnSelected-circle'
                        }
                      >
                        2
                      </Flex>
                      <Text className="Report-Section3-Accordion-Selected-Text">
                        Learn More
                      </Text>
                      <Image ml="auto" mr="26px" w="40px" src={ChevronRight} />
                    </Flex>
                  </>
                ) : reportData.data.report.take_action.education_first ? (
                  <>
                    <Flex
                      _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                      className={
                        section2Accordion === 1
                          ? 'Report-Section3-Accordion-Selected'
                          : 'Report-Section3-Accordion-UnSelected'
                      }
                      onClick={() => {
                        setSection2Accordion(1);
                      }}
                      id="learn"
                      tabIndex={0}
                      role="radio"
                      aria-checked="true"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || 'Space') {
                          setSection2Accordion(0);
                          e.currentTarget.setAttribute(
                            'aria-checked',
                            String(true)
                          );
                          document
                            .getElementById('guidance')
                            ?.setAttribute('aria-checked', String(false));
                        }
                      }}
                    >
                      <Flex
                        className={
                          section2Accordion === 1
                            ? 'Report-Section3-Accordion-Selected-circle'
                            : 'Report-Section3-Accordion-UnSelected-circle'
                        }
                      >
                        1
                      </Flex>
                      <Text className="Report-Section3-Accordion-Selected-Text">
                        Learn More
                      </Text>
                      <Image ml="auto" mr="26px" w="40px" src={ChevronRight} />
                    </Flex>

                    <Flex
                      _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                      className={
                        section2Accordion === 0
                          ? 'Report-Section3-Accordion-Selected'
                          : 'Report-Section3-Accordion-UnSelected'
                      }
                      onClick={() => {
                        setSection2Accordion(0);
                      }}
                      id="guidance"
                      tabIndex={0}
                      role="radio"
                      aria-checked="false"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || 'Space') {
                          setSection2Accordion(1);
                          e.currentTarget.setAttribute(
                            'aria-checked',
                            String(true)
                          );
                          document
                            .getElementById('learn')
                            ?.setAttribute('aria-checked', String(false));
                        }
                      }}
                    >
                      <Flex
                        className={
                          section2Accordion === 0
                            ? 'Report-Section3-Accordion-Selected-circle'
                            : 'Report-Section3-Accordion-UnSelected-circle'
                        }
                      >
                        2
                      </Flex>
                      <Text className="Report-Section3-Accordion-Selected-Text">
                        Get Guidance
                      </Text>
                      <Image ml="auto" mr="26px" w="40px" src={ChevronRight} />
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex
                      role="radio"
                      tabIndex={0}
                      _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                      className={
                        section2Accordion === 0
                          ? 'Report-Section3-Accordion-Selected'
                          : 'Report-Section3-Accordion-UnSelected'
                      }
                      onClick={() => {
                        setSection2Accordion(0);
                      }}
                    >
                      <Flex
                        className={
                          section2Accordion === 0
                            ? 'Report-Section3-Accordion-Selected-circle'
                            : 'Report-Section3-Accordion-UnSelected-circle'
                        }
                      >
                        1
                      </Flex>
                      <Text className="Report-Section3-Accordion-Selected-Text">
                        Get Guidance
                      </Text>
                      <Image ml="auto" mr="26px" w="40px" src={ChevronRight} />
                    </Flex>

                    <Flex
                      role="radio"
                      tabIndex={0}
                      _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                      className={
                        section2Accordion === 1
                          ? 'Report-Section3-Accordion-Selected'
                          : 'Report-Section3-Accordion-UnSelected'
                      }
                      onClick={() => {
                        setSection2Accordion(1);
                      }}
                    >
                      <Flex
                        className={
                          section2Accordion === 1
                            ? 'Report-Section3-Accordion-Selected-circle'
                            : 'Report-Section3-Accordion-UnSelected-circle'
                        }
                      >
                        2
                      </Flex>
                      <Text className="Report-Section3-Accordion-Selected-Text">
                        Learn More
                      </Text>
                      <Image ml="auto" mr="26px" w="40px" src={ChevronRight} />
                    </Flex>
                  </>
                )}
              </Flex>
            </Flex>
            {section2Accordion === 0 ? (
              <Flex
                flex="2"
                className="Report-Section3-Right-Column"
                alignItems={hoveredOver ? 'flex-end' : 'flex-start'}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                >
                  <Flex direction="column">
                    <Text
                      _focus={{boxShadow: '0 0 0 3px #ED00B9'}}
                      className="Report-Section3-Right-Heading"
                    >
                      Get Guidance
                    </Text>
                  </Flex>
                  <Flex>
                    <Image src={AccordionExtra} alt="Advice" />
                  </Flex>
                </Flex>
                <Flex mt="28px" direction="row-reverse">
                  <Flex direction="column">
                    <a
                      tabIndex={0}
                      className="Report-Section3-Human"
                      href={reportData.data.report.cta.advice.human}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        LinkClick(reportData.data.report.cta.advice.human);
                      }}
                      onMouseEnter={() => {
                        setHoveredOver(true);
                      }}
                      onMouseLeave={() => {
                        setHoveredOver(false);
                      }}
                    >
                      <Image
                        src={ProfilePictureBlue}
                        alt="Profile"
                        h="127px"
                        w="127px"
                      />
                      <Text className="Report-Section3-HumanText">Human</Text>
                    </a>

                    <a
                      className="Report-Section3-Button"
                      href={reportData.data.report.cta.advice.human}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        LinkClick(reportData.data.report.cta.advice.human);
                      }}
                    >
                      Schedule a Call
                    </a>
                  </Flex>
                  <Flex direction="column" marginRight="16px">
                    <a
                      className="Report-Section3-Biometric"
                      href={reportData.data.report.cta.advice.digital}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        LinkClick(reportData.data.report.cta.advice.digital);
                      }}
                    >
                      <Image
                        src={BiometricPicture}
                        alt="Biometric"
                        h="127px"
                        w="127px"
                      />
                      <Text className="Report-Section3-BiometricText">
                        Digital
                      </Text>
                    </a>
                    <a
                      className="Report-Section3-Button"
                      href={reportData.data.report.cta.advice.digital}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        LinkClick(reportData.data.report.cta.advice.digital);
                      }}
                    >
                      Retirement Advisor
                    </a>
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <Flex flex="2" className="Report-Section3-Right-Column">
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex direction="column">
                    <Text className="Report-Section3-Right-Heading">
                      Learn More
                    </Text>
                  </Flex>
                  <Flex>
                    <Image src={AccordionExtra2} alt="Advice" />
                  </Flex>
                </Flex>
                <Flex mt="28px" height="200px">
                  <a
                    href={reportData.data.report.cta.education.video}
                    target="_blank"
                    rel="noreferrer"
                    className="Report-Section3-VideoPlace"
                    onClick={() => {
                      LinkClick(reportData.data.report.cta.education.video);
                    }}
                  >
                    <Image src={VideoPlay} opacity="0.8" alt="play" />
                  </a>
                </Flex>

                <Flex justifyContent="space-between">
                  <a
                    className="Report-Section3-Button"
                    href={reportData.data.report.cta.education.brochure}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      LinkClick(reportData.data.report.cta.education.brochure);
                    }}
                  >
                    Diversified Income
                  </a>
                  <a
                    className="Report-Section3-Button"
                    href={reportData.data.report.cta.education.article}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      LinkClick(reportData.data.report.cta.education.article);
                    }}
                  >
                    Learn Article
                  </a>
                </Flex>
              </Flex>
            )}
          </Flex>
          <div>
            <Flex className="Report-Section4">
              <Section id="details">
                <Text className="Report-Section4-Heading">More Details</Text>
                <Flex className="Report-Section4-Tabs" role="tabpanel">
                  <Flex
                    className={
                      section4Tab === 'Future Focus'
                        ? 'Report-Section4-Tab-Selected'
                        : 'Report-Section4-Tab-UnSelected'
                    }
                    onClick={() => {
                      setSection4Tab('Future Focus');
                    }}
                    role="tablist"
                    aria-label={`Future Focus ${reportData.data.report.scores.focus.bucket}`}
                    tabIndex={0}
                    id="Future"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || 'Space') {
                        setSection4Tab('Future Focus');
                        e.currentTarget.setAttribute(
                          'aria-checked',
                          String(true)
                        );
                        document
                          .getElementById('Tendency')
                          ?.setAttribute('aria-checked', String(false));
                        document
                          .getElementById('Desire')
                          ?.setAttribute('aria-checked', String(false));
                      }
                    }}
                  >
                    <Text
                      className={
                        section4Tab === 'Future Focus'
                          ? 'Report-Section4-Tab-Selected-Text'
                          : 'Report-Section4-Tab-UnSelected-Text'
                      }
                    >
                      Future Focus
                    </Text>
                    <Flex
                      className={
                        section4Tab === 'Future Focus'
                          ? 'Report-Section4-Tab-Selected-Alert'
                          : 'Report-Section4-Tab-UnSelected-Alert'
                      }
                    >
                      {reportData.data.report.scores.focus.bucket.toUpperCase()}
                    </Flex>
                  </Flex>
                  <Flex
                    className={
                      section4Tab === 'Tendency to Avoid Risk'
                        ? 'Report-Section4-Tab-Selected'
                        : 'Report-Section4-Tab-UnSelected'
                    }
                    onClick={() => {
                      setSection4Tab('Tendency to Avoid Risk');
                    }}
                    role="tablist"
                    aria-label={`Tendency to avoid risk ${reportData.data.report.scores.risk.bucket}`}
                    tabIndex={0}
                    id="Tendency"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || 'Space') {
                        setSection4Tab('Tendency to Avoid Risk');
                        e.currentTarget.setAttribute(
                          'aria-checked',
                          String(true)
                        );
                        document
                          .getElementById('Future')
                          ?.setAttribute('aria-checked', String(false));
                        document
                          .getElementById('Desire')
                          ?.setAttribute('aria-checked', String(false));
                      }
                    }}
                  >
                    <Text
                      className={
                        section4Tab === 'Tendency to Avoid Risk'
                          ? 'Report-Section4-Tab-Selected-Text'
                          : 'Report-Section4-Tab-UnSelected-Text'
                      }
                    >
                      Tendency to Avoid Risk
                    </Text>
                    <Flex
                      className={
                        section4Tab === 'Tendency to Avoid Risk'
                          ? 'Report-Section4-Tab-Selected-Alert'
                          : 'Report-Section4-Tab-UnSelected-Alert'
                      }
                    >
                      {reportData.data.report.scores.risk.bucket.toUpperCase()}
                    </Flex>
                  </Flex>
                  <Flex
                    className={
                      section4Tab === 'Desire for Certainty'
                        ? 'Report-Section4-Tab-Selected'
                        : 'Report-Section4-Tab-UnSelected'
                    }
                    onClick={() => {
                      setSection4Tab('Desire for Certainty');
                    }}
                    role="tablist"
                    aria-label={`Desire for Certainty ${reportData.data.report.scores.certainty.bucket}`}
                    tabIndex={0}
                    id="Desire"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || 'Space') {
                        setSection4Tab('Desire for Certainty');
                        e.currentTarget.setAttribute(
                          'aria-checked',
                          String(true)
                        );
                        document
                          .getElementById('Future')
                          ?.setAttribute('aria-checked', String(false));
                        document
                          .getElementById('Tendency')
                          ?.setAttribute('aria-checked', String(false));
                      }
                    }}
                  >
                    <Text
                      className={
                        section4Tab === 'Desire for Certainty'
                          ? 'Report-Section4-Tab-Selected-Text'
                          : 'Report-Section4-Tab-UnSelected-Text'
                      }
                    >
                      Desire for Certainty
                    </Text>
                    <Flex
                      className={
                        section4Tab === 'Desire for Certainty'
                          ? 'Report-Section4-Tab-Selected-Alert'
                          : 'Report-Section4-Tab-UnSelected-Alert'
                      }
                    >
                      {reportData.data.report.scores.certainty.bucket.toUpperCase()}
                    </Flex>
                  </Flex>
                </Flex>
                <Section4Accordion
                  reportData={reportData.data}
                  currentTab={section4Tab}
                />
              </Section>
            </Flex>
          </div>
          <Flex className="Report-Section5">
            <Flex>
              <Image src={SyntoniqLogo} alt="syntoniq" />
            </Flex>
            <Flex className="Report-Section5-CopyRight">Copyright 2021</Flex>
            <Flex>
              <Text className="Report-Section5-Link">About</Text>
              <Text className="Report-Section5-Link">Support</Text>
              <a
                className="Report-Section5-Link"
                href={reportData.data.report.cta.footer}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  LinkClick(reportData.data.report.cta.footer);
                }}
              >
                Back to <p className="Report-Section5-Link-TIAA">TIAA.org</p>
              </a>
            </Flex>
          </Flex>
        </Flex>
      </ScrollingProvider>
    )
  ) : reportData.message === '' ? (
    <Flex className="Loading-Container">
      <Text className="Loading-Text">
        Our behavioral engines are now running, your report should be ready in a
        moment...
      </Text>
      <Flex>
        <Image
          src="https://res.cloudinary.com/dmrpikki0/video/upload/v1634299125/Solera-Assessment/animation_rdjxaz.gif"
          alt="Loading"
        />
      </Flex>
    </Flex>
  ) : (
    <Flex alignItems="center" justifyContent="center" w="100%" minH="100vh">
      {' '}
      <h1 className="Report-NotGenerated">{reportData.message}</h1>
    </Flex>
  );
};

export default Report;
