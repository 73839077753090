const scoreConvertor3 = (choice: string) => {
  const newChoice = parseFloat(choice);
  return ((1 - newChoice) * 100).toString();
};

const scoreConvertor4 = (choice: string) => {
  const newChoice = parseFloat(choice);
  return (newChoice * 10).toString();
};

const scoreConvertor4_8_9_10_11_13 = (choice: string) => {
  const newChoice = parseInt(choice, 10);
  return (newChoice * 10).toString();
};
const scoreConvertor13 = (choice: string) => {
  const newChoice = parseFloat(choice);
  return (newChoice * 100).toString();
};
const scoreConvertor6 = (choice: string) => {
  return choice.split('-');
};
interface Responses {
  Q1: {value: string; score: string}[];
  Q2: {value: string; score: string}[];
  Q3: {value: string; score: string}[];
  Q4: {value: string; score: string}[];
  Q5: {value: string; score: string}[];
  Q6: {value: string; score: string}[];
  Q7: {value: string; score: string}[];
  Q8: {value: string; score: string}[];
  Q9: {value: string; score: string}[];
  Q10: {value: string; score: string}[];
  Q11: {value: string; score: string}[];
  Q12: {value: string; score: string}[];
  Q13: {value: string; score: string}[];
  Q14: {value: string; score: string}[];
}

const FetchRearrange = (responses: Responses) => {
  const returnArray: string[][] = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  if (responses.Q1[0]) {
    returnArray[0] = [responses.Q1[0].score, responses.Q1[1].score];
  }
  if (responses.Q2[0]) {
    returnArray[1] = [responses.Q2[0].value];
  }
  if (responses.Q3[0]) {
    returnArray[2] = [responses.Q3[0].value];
  }
  if (responses.Q4[0]) {
    returnArray[3] = [scoreConvertor3(responses.Q4[0].score)];
  }
  if (responses.Q5[0]) {
    returnArray[4] = [scoreConvertor4(responses.Q5[0].score)];
  }
  if (responses.Q6[0]) {
    returnArray[5] = [responses.Q6[0].score];
  }
  if (responses.Q7[0]) {
    returnArray[6] = [
      scoreConvertor6(responses.Q7[0].value)[0],
      scoreConvertor6(responses.Q7[0].value)[1],
    ];
  }
  if (responses.Q8[0]) {
    returnArray[7] = [scoreConvertor4_8_9_10_11_13(responses.Q8[0].score)];
  }
  if (responses.Q9[0]) {
    returnArray[8] = [scoreConvertor4_8_9_10_11_13(responses.Q9[0].score)];
  }
  if (responses.Q10[0]) {
    returnArray[9] = [scoreConvertor4_8_9_10_11_13(responses.Q10[0].score)];
  }
  if (responses.Q11[0]) {
    returnArray[10] = [scoreConvertor4_8_9_10_11_13(responses.Q11[0].score)];
  }
  if (responses.Q12[0]) {
    if (responses.Q12[2]) {
      returnArray[11] = [
        responses.Q12[0].value,
        responses.Q12[1].value,
        responses.Q12[2].value,
      ];
    } else if (responses.Q12[1]) {
      returnArray[11] = [responses.Q12[0].value, responses.Q12[1].value];
    } else {
      returnArray[11] = [responses.Q12[0].value];
    }
  }
  if (responses.Q13[0]) {
    if (responses.Q13[2]) {
      returnArray[12] = [
        responses.Q13[0].value,
        responses.Q13[1].value,
        responses.Q13[2].value,
      ];
    } else if (responses.Q13[1]) {
      returnArray[12] = [responses.Q13[0].value, responses.Q13[1].value];
    } else {
      returnArray[12] = [responses.Q13[0].value];
    }
  }
  if (responses.Q14[0]) {
    returnArray[13] = [
      scoreConvertor13(responses.Q14[0].score),
      scoreConvertor13(responses.Q14[1].score),
    ];
  }

  return returnArray;
};

export default FetchRearrange;
