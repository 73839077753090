import {Flex} from '@chakra-ui/layout';
import React, {useContext} from 'react';
import {useMediaQuery} from 'react-responsive';
import {AnswerContext} from '../../Context';
import './styles/styles.css';
import {Button, HStack} from '@chakra-ui/react';

interface Props {
  nextQuestion: (maxProgress: number) => void;
  prevQuestion: (maxProgress: number) => void;
  progress: {
    total: number;
    current: number;
  };
  nextOptions: {
    doNextAfter: number;
    current: number;
    lastMax: number;
  };
}

interface FooterProgressProps {
  progress: {
    total: number;
    current: number;
  };
  index: number;
}

const FooterProgress: React.FC<FooterProgressProps> = ({progress, index}) => {
  if (index < progress.current) {
    return <Flex className="Footer-Answered"></Flex>;
  } else if (index === progress.current) {
    return <Flex className="Footer-Answering"></Flex>;
  }
  return <Flex className="Footer-ToAnswer"></Flex>;
};

const Footer: React.FC<Props> = ({
  nextQuestion,
  prevQuestion,
  progress,
  nextOptions,
}) => {
  const answerData = useContext(AnswerContext);
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const isDisabled =
    progress.current === 11 || progress.current === 12
      ? answerData?.answers[progress.current][0] &&
        answerData?.answers[progress.current][1] &&
        answerData?.answers[progress.current][2]
        ? false
        : true
      : progress.current === progress.total - 1
      ? answerData?.answers[progress.current][0] &&
        answerData?.answers[progress.current][1] &&
        answerData?.answers[progress.current][1].length === 2 &&
        parseInt(answerData?.answers[progress.current][1], 10) >= 10
        ? false
        : true
      : answerData?.answers[progress.current][nextOptions.current]
      ? false
      : true;
  return (
    <>
      <Flex className="Footer-Container" direction="column">
        {/* {isVisible && isMobile && (
          <>
          <div className="Footer-ScrollToBottom">
          </div>

          <Text position="absolute">More Options</Text>
          </>
        )} */}
        {!isMobile && (
          <Flex className="Footer-ProgressSection">
            {Array.from(Array(progress.total)).map((x, index) => (
              <FooterProgress key={index} index={index} progress={progress} />
            ))}
          </Flex>
        )}
        <Flex bg="white" className="Footer-ButtonSection">
          <HStack gap="4.5em">
            <Button
              variant="previous"
              onClick={() => {
                prevQuestion(nextOptions.lastMax);
              }}
            >
              Previous
            </Button>
            <Button
              variant="next"
              isDisabled={isDisabled}
              onClick={() => {
                if (progress.current === progress.total - 1) {
                  if (
                    answerData?.answers[progress.current][0] &&
                    answerData?.answers[progress.current][1] &&
                    answerData?.answers[progress.current][1].length === 2 &&
                    parseInt(answerData?.answers[progress.current][1], 10) >= 10
                  ) {
                    nextQuestion(nextOptions.doNextAfter);
                  }
                } else if (progress.current === 11 || progress.current === 12) {
                  if (
                    answerData?.answers[progress.current][0] &&
                    answerData?.answers[progress.current][1] &&
                    answerData?.answers[progress.current][2]
                  ) {
                    nextQuestion(nextOptions.doNextAfter);
                  }
                } else if (
                  answerData?.answers[progress.current][nextOptions.current]
                ) {
                  nextQuestion(nextOptions.doNextAfter);
                }
              }}
            >
              {progress.current !== progress.total - 1
                ? 'Next'
                : 'Complete Quiz'}
            </Button>
          </HStack>
        </Flex>
      </Flex>
    </>
  );
};

export default Footer;
