import {ASSESSMENT_POST_API_URL} from '../../Data';
import Axios from 'axios';
import {
  QuestionData,
  DispatchType,
  DataAction,
  DispatchPost,
  Failure,
} from '../type';
import API_RESPONSE_DATA from '../../Data';
const success = 201;

export function PostAssessmentSuccess(id: string) {
  const action: {type: string; post: {fetched: boolean; id: string}} = {
    type: 'POST_ASSESSMENT_SUCCESS',
    post: {
      fetched: true,
      id: id,
    },
  };
  return action;
}

export function FetchAssessmentProgressSuccess(questionData: QuestionData) {
  const action: DataAction = {
    type: 'FETCH_ASSESSMENT_PROGRESS_SUCCESS',
    questionData: questionData,
    error: {
      code: '',
      message: '',
    },
  };
  return action;
}

export function FetchAssessmentProgressFailure(
  questionData: QuestionData,
  code: string,
  message: string
) {
  const action: DataAction = {
    type: 'FETCH_ASSESSMENT_PROGRESS_SUCCESS',
    questionData: questionData,
    error: {
      code: code,
      message: message,
    },
  };

  return action;
}

export function FetchData(warning: () => void, errorToast: () => void) {
  return (dispatch: DispatchPost) => {
    Axios.post<QuestionData>(ASSESSMENT_POST_API_URL)
      .then((response) => {
        if (response?.status === success) {
          dispatch(PostAssessmentSuccess(response?.data?.id));
        } else {
          console.warn('Error posting data', response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
}

export function FetchProgress(
  assessmentId: string,
  warning: () => void,
  errorToast: () => void
) {
  return (dispatch: DispatchType) => {
    Axios.get<QuestionData | Failure>(
      ASSESSMENT_POST_API_URL + `/${assessmentId}`
    )
      .then((response) => {
        if (response?.status === success || response?.status === 200) {
          dispatch(
            FetchAssessmentProgressSuccess(response?.data as QuestionData)
          );
        } else {
          console.warn('Error fetching data progress', response);
        }
      })
      .catch((error) => {
        if (error?.response?.status >= 400 && error?.response?.status <= 499) {
          console.warn(
            `Error fetching data progress: ${error?.response?.status}`,
            error
          );
          warning();
          dispatch(
            FetchAssessmentProgressFailure(
              {...API_RESPONSE_DATA, id: '-1'},
              '404',
              '404. Assessment does not exist.'
            )
          );
        } else if (error?.response?.status === 500) {
          console.warn('Error fetching data progress: 500', error);
          errorToast();
          dispatch(
            FetchAssessmentProgressFailure(
              {...API_RESPONSE_DATA, id: '-1'},
              '500',
              'There was a problem in getting your assessment.'
            )
          );
        } else {
          errorToast();
          console.warn(error);
        }
      });
  };
}

export function PutProgress(
  assessmentId: string,
  payload: {
    question_number: number;
    responses: {
      value: string;
      score: string;
    }[];
  }[],
  warning: () => void,
  errorToast: () => void
) {
  return (dispatch: DispatchType) => {
    Axios.put<QuestionData>(
      ASSESSMENT_POST_API_URL + `/${assessmentId}`,
      payload
    )
      .then((response) => {
        if (response?.status) {
        } else {
          console.warn('Error putting data progress', response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };
}

export function PostProgress(
  assessmentId: string,
  payload: {
    client: {
      first_name: string;
      age_range: {
        low: number;
        high: number;
      };
    };
    submission: {
      question_number: number;
      responses: {
        value: string;
        score: string;
      }[];
    }[];
  },
  warning: () => void,
  errorToast: () => void
) {
  return (dispatch: DispatchType) => {
    Axios.post<QuestionData>(
      ASSESSMENT_POST_API_URL + `/${assessmentId}`,
      payload
    )
      .then((response) => {
        if (response?.status) {
        } else {
          console.warn('Error posting data progress', response);
        }
      })
      .catch((error) => {
        console.warn(error);
        errorToast();
      });
  };
}
