import {Flex, Text} from '@chakra-ui/layout';
import React from 'react';
import './styles/styles.css';
import {useContext} from 'react';
import OptionSelect from '../../Assets/Option-Select-Check.svg';
import {Image} from '@chakra-ui/image';
import {AnswerContext} from '../../Context';
import {isUndefined} from 'lodash';
interface Props {
  options: string[];
  progress: {
    total: number;
    current: number;
  };
  nextOptions: {
    doNextAfter: number;
    current: number;
  };
  max?: number;
  stateValue: string;
}
interface CheckBoxProps {
  options: string[];
  max: number;
  progress: {
    total: number;
    current: number;
  };
  nextOptions: {
    doNextAfter: number;
    current: number;
  };
}

const pickUpFromState = (
  answerData: {
    answers: string[][];
    setAnswers: React.Dispatch<React.SetStateAction<string[][]>>;
  } | null,
  defaultValue: string,
  progress: {
    total: number;
    current: number;
  },
  nextOptions: {
    doNextAfter: number;
    current: number;
  }
) => {
  if (
    isUndefined(answerData) ||
    isUndefined(answerData?.answers[progress.current][nextOptions.current]) ||
    answerData?.answers[progress.current][nextOptions.current] === null
  ) {
    return defaultValue;
  }
  if (answerData) {
    if (answerData.answers[progress.current][nextOptions.current] === '') {
      return defaultValue;
    }
    return answerData.answers[progress.current][nextOptions.current];
  }
  return defaultValue;
};

const pickUpFromStateCheckbox = (
  answerData: {
    answers: string[][];
    setAnswers: React.Dispatch<React.SetStateAction<string[][]>>;
  } | null,
  defaultValue: string[],
  progress: {
    total: number;
    current: number;
  }
) => {
  if (
    isUndefined(answerData) ||
    isUndefined(answerData?.answers[progress.current]) ||
    answerData?.answers[progress.current] === null
  ) {
    return defaultValue;
  }
  if (answerData) {
    return answerData.answers[progress.current] as string[];
  }
  return defaultValue;
};

const findChecked = (selectedOption: string, state: string[]) => {
  const result = state.filter((option) => {
    return option === selectedOption;
  });
  if (result.length > 0) {
    return true;
  }
  return false;
};

const RadioOptions: React.FC<Props> = ({options, progress, nextOptions}) => {
  const answerData = useContext(AnswerContext);
  const value = pickUpFromState(answerData, '', progress, nextOptions);
  const handleValueChange = (newValue: string) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current][nextOptions.current] === undefined) {
        newAnswers[progress.current][nextOptions.current] = newValue;
      }
      newAnswers[progress.current][nextOptions.current] = newValue;
      answerData?.setAnswers([...newAnswers]);
    }
  };
  return (
    <Flex direction="column" role="radiogroup">
      {options.map((option, index) => {
        return (
          <Flex
            className={
              value === option ? 'Option-Selected' : 'Option-Unselected'
            }
            onKeyPress={(e) => {
              if (e.key === 'Enter' || 'Space') {
                handleValueChange(option);
                // @ts-ignore
                let checked =
                  e.currentTarget.getAttribute('aria-checked') === 'true';
                e.currentTarget.setAttribute('aria-checked', String(!checked));
              }
            }}
            aria-checked="false"
            role="radio"
            key={index}
            bg="white"
            onClick={() => {
              handleValueChange(option);
            }}
            tabIndex={0}
            _focus={{
              boxShadow: '0 0 0 3px #ED00B9',
            }}
          >
            <Image
              src={OptionSelect}
              alt="Selected"
              margin-left="14px"
              visibility={value === option ? 'visible' : 'hidden'}
            />
            <Text
              className={
                value === option
                  ? 'Option-TextSelected'
                  : 'Option-TextUnselected'
              }
            >
              {option}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export const CheckboxOptions: React.FC<CheckBoxProps> = ({
  options,
  max,
  progress,
  nextOptions,
}) => {
  const answerData = useContext(AnswerContext);
  const value = pickUpFromStateCheckbox(answerData, [''], progress);
  const handleValueChange = (newValue: string[]) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current] === undefined) {
        newAnswers[progress.current] = newValue;
      }
      newAnswers[progress.current] = newValue;
      answerData?.setAnswers([...newAnswers]);
    }
  };
  return (
    <Flex direction="column" role="radiogroup">
      {options.map((option, index) => {
        const result = findChecked(option, value);
        return (
          <Flex
            className={result ? 'Option-Selected' : 'Option-Unselected'}
            key={index}
            bg="white"
            role="radio"
            tabIndex={0}
            _focus={{
              boxShadow: '0 0 0 3px #ED00B9',
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || 'Space') {
                if (result) {
                  const answers = answerData?.answers[progress.current].filter(
                    (singleOption) => {
                      return singleOption !== option;
                    }
                  ) as string[];
                  handleValueChange(answers);
                } else {
                  if (value.length < max) {
                    const answers = [
                      ...(answerData?.answers[progress.current] as string[]),
                      option,
                    ];
                    handleValueChange(answers);
                  }
                }
                let checked =
                  e.currentTarget.getAttribute('aria-checked') === 'true';
                e.currentTarget.setAttribute('aria-checked', String(!checked));
              }
            }}
            aria-checked="false"
            onClick={() => {
              if (result) {
                const answers = answerData?.answers[progress.current].filter(
                  (singleOption) => {
                    return singleOption !== option;
                  }
                ) as string[];
                handleValueChange(answers);
              } else {
                if (value.length < max) {
                  const answers = [
                    ...(answerData?.answers[progress.current] as string[]),
                    option,
                  ];
                  handleValueChange(answers);
                }
              }
            }}
          >
            <Image
              src={OptionSelect}
              alt="Selected"
              margin-left="14px"
              visibility={result ? 'visible' : 'hidden'}
            />
            <Text
              className={
                result ? 'Option-TextSelected' : 'Option-TextUnselected'
              }
            >
              {option}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default RadioOptions;
