import {createContext} from 'react';

export const CounterContext = createContext<{
  questionCounter: number;
  sectionCounter: number;
} | null>(null);

export const AnswerContext = createContext<{
  answers: string[][];
  setAnswers: React.Dispatch<React.SetStateAction<string[][]>>;
} | null>(null);
