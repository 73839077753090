import {extendTheme} from '@chakra-ui/react';
import {globalStyles} from './styles';
import {font} from './foundations/fonts';
import {breakpoints} from './foundations/breakpoints';
import {buttonStyles} from './components/button';

export default extendTheme(
  {breakpoints}, // Breakpoints
  globalStyles,
  font,
  buttonStyles
);
