import Home from './Pages/Home';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Report from './Pages/Report';
import NotFound from './Pages/Not-Found';
import StartPage from './Pages/StartPage';
const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <StartPage />
        </Route>
        <Route path="/assessments/:assessmentID" exact>
          <Home />
        </Route>
        <Route path="/assessments/:assessmentID/report">
          <Report />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
