import {mode} from '@chakra-ui/theme-tools';

export const globalStyles = {
  colors: {
    grey: {
      light: '#C4C4C4',
    },
    blue: {
      dark: '#003865',
      semiMedium: '#0097B8',
      medium: '#0067b9',
      semiLight: '#00a3e0',
      light: '#99d6ea',
      extraLight: '#CFE8FF',
    },
    purple: {
      dark: '#343579',
      medium: '#753bbd',
      light: '#B790D9',
    },
    bg: {
      dark: 'rgba(190,198,196,0.4)',
      medium: 'rgba(190,198,196,0.2)',
      light: 'rgba(190,198,196,0.07)',
    },
    accent: '#FFA300',
  },
  shadows: {
    outline: '0 0 0 3px #ED00B9',
    boxShadow: '0 0 0 3px #ED00B9',
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode('blue.dark', 'blue.dark')(props),
        fontFamily: 'Inter,Helvetica, sans-serif',
      },
      html: {
        fontFamily: 'Inter,Helvetica, sans-serif',
      },
      '.js-focus-visible :focus:not([data-focus-visible-added])': {
        outline: 'none',
        boxShadow: 'none',
      },
      '.focus-visible': {
        boxShadow: '0 0 0 3px #ED00B9',
      },
    }),
  },
};
