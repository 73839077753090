import React from 'react';
import {render} from 'react-dom';
import {ChakraProvider} from '@chakra-ui/react';
import theme from './theme/theme';
import App from './App';
import './index.css';
import './Fonts/Gill-Sans/Gill Sans.otf';
import './Fonts/Gill-Sans/Gill Sans Bold.otf';
import './Fonts/Gill-Sans/Gill Sans Medium.otf';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';
import 'focus-visible/dist/focus-visible';
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
  Offline as OfflineIntegration,
} from '@sentry/integrations';

import configureStore from './Redux/createStore';

Sentry.init({
  dsn: 'https://30a44e10699e479ebb5e9e9eed0db8a8@o575835.ingest.sentry.io/6117466',
  integrations: [
    new Integrations.BrowserTracing(),
    new ExtraErrorDataIntegration({depth: 10}),
    new CaptureConsoleIntegration({
      levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
    }),
    new OfflineIntegration({maxStoredEvents: 30}),
    new SentryRRWeb(),
  ],
  normalizeDepth: 10,
  environment: process.env.NODE_ENV,
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  release: process.env.RELEASE || 'Unreleased',
});

const store = configureStore();
render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.querySelector('#root')
);
