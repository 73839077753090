import {Flex} from '@chakra-ui/layout';
import {isUndefined} from 'lodash';
import React, {useEffect, useState, useContext} from 'react';
import {
  CheckboxOptions,
  RadioOptions,
  SliderType3,
  SliderType4,
  SliderType5,
  SliderType6,
  SliderType7,
  SliderWithImage,
  SliderWithInput,
} from '../../Components';
import {Question} from '../../type';
import './styles/styles.css';
import PlantImage from '../../Assets/Plant-2.svg';
import {Image} from '@chakra-ui/image';
import {Heading} from '@chakra-ui/layout';
import {SlideFade} from '@chakra-ui/transition';
import {FormControl, FormLabel} from '@chakra-ui/form-control';
import {useMediaQuery} from 'react-responsive';
import {AnswerContext} from '../../Context';
import {
  HeadingProps,
  Input,
  InputProps,
  Spacer,
  VisuallyHidden,
} from '@chakra-ui/react';

const OptionsRearrange = (options: string[][]) => {
  let data: string[] = [];
  options.forEach((singleOption) => {
    data.push(singleOption[0]);
  });
  return data;
};

interface Props {
  questionData: Question;
  progress: {
    total: number;
    current: number;
  };
  nextOptions: {
    doNextAfter: number;
    current: number;
  };
}

const QuestionContainer: React.FC<Props> = ({
  questionData,
  progress,
  nextOptions,
}) => {
  const hookProgress = progress.current;
  const hookOptions = nextOptions.current;
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 900px)'});
  const isMobile = useMediaQuery({query: '(max-width: 700px)'});
  const [animateBig, setAnimateBig] = useState(true);
  const [animateSmall, setAnimateSmall] = useState(true);
  const answerData = useContext(AnswerContext);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [progress.current]);
  useEffect(() => {
    setAnimateBig(false);
    setAnimateSmall(false);
    let timer1 = setTimeout(() => {
      setAnimateBig(true);
    }, 500);
    let timer2 = setTimeout(() => {
      setAnimateSmall(true);
    }, 1100);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [hookProgress, hookOptions]);
  if (progress.current === 4) {
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText mb="56px">
                {questionData && questionData.context?.body}
              </DescriptionText>
            </SlideFade>
            <Flex alignItems="center" mx="auto">
              <SliderType3 progress={progress} nextOptions={nextOptions} />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (progress.current === 5) {
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText>
                {questionData && questionData.context?.preface}
              </DescriptionText>
            </SlideFade>
            {questionData && questionData.context?.body ? (
              <SlideFade
                in={animateSmall}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.4},
                }}
              >
                <PrefaceText marginBottom="56px">
                  {questionData && questionData.context?.body}
                </PrefaceText>
              </SlideFade>
            ) : (
              <Spacer height="26px" />
            )}
            <Flex alignItems="center" mx="auto">
              <SliderType4 progress={progress} nextOptions={nextOptions} />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (
    progress.current === 7 ||
    progress.current === 8 ||
    progress.current === 9 ||
    progress.current === 10
  ) {
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText marginBottom="56px">
                Use the slider to indicate your response.
              </DescriptionText>
            </SlideFade>
            {questionData && questionData.context?.body ? (
              <SlideFade
                in={animateSmall}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.4},
                }}
              >
                <PrefaceText marginBottom="16px">
                  {questionData && questionData.context?.body}
                </PrefaceText>
              </SlideFade>
            ) : (
              <Spacer height="26px" />
            )}
            <Flex alignItems="center" mx="auto">
              <SliderType5 progress={progress} nextOptions={nextOptions} />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (questionData.question_type === 'CARD') {
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <VisuallyHidden>
                {questionData && questionData.context?.body}
              </VisuallyHidden>
              <DescriptionText>
                {questionData && questionData.context?.body}
              </DescriptionText>
            </SlideFade>
            {questionData.context?.postface ? (
              <SlideFade
                in={animateSmall}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.4},
                }}
              >
                <PrefaceText>
                  {questionData && questionData.context?.postface}
                </PrefaceText>
              </SlideFade>
            ) : (
              <Spacer height="26px" />
            )}
            <Flex>
              <RadioOptions
                options={[
                  questionData.card?.context.left?.text.center as string,
                  questionData.card?.context.right.text.center as string,
                ]}
                progress={progress}
                nextOptions={nextOptions}
                stateValue={
                  answerData?.answers[progress.current][
                    nextOptions.current
                  ] as string
                }
              />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (questionData.question_type === 'RADIO') {
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText>
                {questionData && questionData.context?.preface}
              </DescriptionText>
            </SlideFade>
            {questionData.context?.body ? (
              <SlideFade
                in={animateSmall}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.4},
                }}
              >
                <PrefaceText>
                  {questionData && questionData.context?.body}
                </PrefaceText>
              </SlideFade>
            ) : (
              <Spacer height="26px" />
            )}
            <Flex>
              <RadioOptions
                options={
                  questionData && questionData?.radio
                    ? OptionsRearrange(questionData?.radio)
                    : []
                }
                stateValue={
                  answerData?.answers[progress.current][
                    nextOptions.current
                  ] as string
                }
                progress={progress}
                nextOptions={nextOptions}
              />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (questionData.question_type === 'MULTI_CARD') {
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText marginBottom="56px" marginTop="0px">
                {questionData && questionData.context?.body}
              </DescriptionText>
            </SlideFade>
            <Flex>
              <CheckboxOptions
                options={
                  questionData && questionData?.cards
                    ? OptionsRearrange(questionData?.cards)
                    : []
                }
                progress={progress}
                nextOptions={nextOptions}
                max={3}
              />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (questionData.question_type === 'SLIDER') {
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText>
                {questionData && questionData.context?.preface}
              </DescriptionText>
            </SlideFade>
            {questionData.context?.body ? (
              <SlideFade
                in={animateSmall}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.4},
                }}
              >
                <PrefaceText marginBottom="20px">
                  {questionData && questionData.context?.body}
                </PrefaceText>
              </SlideFade>
            ) : (
              <Spacer height="26px" />
            )}
            <Flex alignItems="center" mx="auto">
              <SliderWithImage progress={progress} nextOptions={nextOptions} />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (questionData.question_type === 'TREE') {
    if (nextOptions.current === 0) {
      return (
        <Flex className="Question-Container">
          <Flex direction="column" className="Question-LeftColumn">
            <Flex className="Question-HeadingContainer" direction="column">
              <SlideFade
                in={animateBig}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.1},
                }}
              >
                <DescriptionText marginBottom="56px">
                  {questionData &&
                    questionData.tree &&
                    questionData?.tree.nodes[0].context.body}
                </DescriptionText>
              </SlideFade>
              <Flex>
                <RadioOptions
                  options={['Yes', 'No']}
                  stateValue={
                    answerData?.answers[progress.current][
                      nextOptions.current
                    ] as string
                  }
                  progress={progress}
                  nextOptions={nextOptions}
                />
              </Flex>
            </Flex>
          </Flex>
          {!isTabletOrMobile && (
            <Flex className="Question-ImageContainer">
              <Image
                userSelect="none"
                width="100%"
                height="100%"
                src={PlantImage}
                alt="Plant"
              />
            </Flex>
          )}
        </Flex>
      );
    }
    return answerData?.answers[progress.current][0] === 'Yes' ? (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText marginBottom="56px">
                {questionData &&
                  questionData.tree &&
                  questionData?.tree.nodes[2].context.body}
              </DescriptionText>
            </SlideFade>
            <Flex>
              <RadioOptions
                options={['Yes', 'No']}
                stateValue={
                  answerData?.answers[progress.current][
                    nextOptions.current
                  ] as string
                }
                progress={progress}
                nextOptions={nextOptions}
              />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    ) : (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText marginBottom="56px">
                {questionData &&
                  questionData.tree &&
                  questionData?.tree.nodes[1].context.body}
              </DescriptionText>
            </SlideFade>
            <Flex>
              <RadioOptions
                options={['Yes', 'No']}
                stateValue={
                  answerData?.answers[progress.current][
                    nextOptions.current
                  ] as string
                }
                progress={progress}
                nextOptions={nextOptions}
              />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (
    questionData.question_type === 'MULTI_SLIDER' &&
    progress.current !== 13
  ) {
    if (nextOptions.current === 0) {
      return (
        <Flex className="Question-Container">
          <Flex direction="column" className="Question-LeftColumn">
            <Flex className="Question-HeadingContainer" direction="column">
              <SlideFade
                in={animateBig}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.1},
                }}
              >
                <DescriptionText mb="56px">
                  {questionData &&
                    questionData.sliders &&
                    questionData?.sliders[0].context.body}
                </DescriptionText>
              </SlideFade>
              <Flex alignItems="center" mx="auto">
                <SliderWithInput
                  progress={progress}
                  nextOptions={nextOptions}
                />
              </Flex>
              <Flex className="Question-HelperText">
                Please move the slider to indicate the dollar amount you like to
                receive in a year.
              </Flex>
            </Flex>
          </Flex>
          {!isTabletOrMobile && (
            <Flex className="Question-ImageContainer">
              <Image
                userSelect="none"
                width="100%"
                height="100%"
                src={PlantImage}
                alt="Plant"
              />
            </Flex>
          )}
        </Flex>
      );
    }
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText mb="56px">
                {questionData &&
                  questionData.sliders &&
                  questionData?.sliders[1].context.body}
              </DescriptionText>
            </SlideFade>

            <Flex alignItems="center" mx="auto">
              <SliderWithInput progress={progress} nextOptions={nextOptions} />
            </Flex>

            <Flex className="Question-HelperText">
              Please move the slider to indicate the dollar amount you like to
              receive in 5 years.
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (progress.current === 13) {
    if (nextOptions.current === 0) {
      return (
        <Flex className="Question-Container">
          <Flex direction="column" className="Question-LeftColumn">
            <Flex className="Question-HeadingContainer" direction="column">
              <SlideFade
                in={animateBig}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.4},
                }}
              >
                <DescriptionText marginBottom="56px">
                  Use the slider to indicate your response.
                </DescriptionText>
              </SlideFade>
              {questionData &&
              questionData.sliders &&
              questionData?.sliders[0].context.body ? (
                <SlideFade
                  in={animateSmall}
                  offsetX="-50px"
                  transition={{
                    enter: {duration: 0.8},
                    exit: {duration: 0.4},
                  }}
                >
                  <PrefaceText marginBottom="16px">
                    {questionData &&
                      questionData.sliders &&
                      questionData?.sliders[0].context.body}
                  </PrefaceText>
                </SlideFade>
              ) : (
                <Spacer height="26px" />
              )}

              <Flex alignItems="center" mx="auto">
                <SliderType6 progress={progress} nextOptions={nextOptions} />
              </Flex>
            </Flex>
          </Flex>
          {!isTabletOrMobile && (
            <Flex className="Question-ImageContainer">
              <Image
                userSelect="none"
                width="100%"
                height="100%"
                src={PlantImage}
                alt="Plant"
              />
            </Flex>
          )}
        </Flex>
      );
    }
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex className="Question-HeadingContainer" direction="column">
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <DescriptionText marginBottom="56px">
                Use the slider to indicate your response.
              </DescriptionText>
            </SlideFade>
            {questionData &&
            questionData.sliders &&
            questionData?.sliders[1].context.body ? (
              <SlideFade
                in={animateSmall}
                offsetX="-50px"
                transition={{
                  enter: {duration: 0.8},
                  exit: {duration: 0.4},
                }}
              >
                <PrefaceText marginBottom="16px">
                  {questionData &&
                    questionData.sliders &&
                    questionData?.sliders[1].context.body}
                </PrefaceText>
              </SlideFade>
            ) : (
              <Spacer height="26px" />
            )}

            <Flex alignItems="center" mx="auto" marginTop="16px">
              <SliderType7 progress={progress} nextOptions={nextOptions} />
            </Flex>
          </Flex>
        </Flex>
        {!isTabletOrMobile && (
          <Flex className="Question-ImageContainer">
            <Image
              userSelect="none"
              width="100%"
              height="100%"
              src={PlantImage}
              alt="Plant"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (questionData.question_type === 'MULTI_INPUT') {
    return (
      <Flex className="Question-Container">
        <Flex direction="column" className="Question-LeftColumn">
          <Flex
            className="Question-HeadingContainer"
            direction="column"
            maxWidth="527px"
          >
            <SlideFade
              in={animateBig}
              offsetX="-50px"
              transition={{
                enter: {duration: 0.8},
                exit: {duration: 0.4},
              }}
            >
              <Flex className="Question-DescriptionText" mb="38px">
                To personalize your analysis, we just need your first name and
                age:
              </Flex>
            </SlideFade>
            <Flex direction={isMobile ? 'column' : 'row'} mt={8}>
              <FormControl id="fName">
                <FormLabel color="white">What is your first name?</FormLabel>
                <InputOne
                  value={answerData?.answers[progress.current][0] || ''}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const alphabetReg = /^[a-zA-Z]*$/;
                    if (alphabetReg.test(newValue)) {
                      const newAnswers = answerData?.answers;
                      if (!isUndefined(newAnswers)) {
                        if (newAnswers[progress.current][0] === undefined) {
                          newAnswers[progress.current][0] = newValue;
                        }
                        newAnswers[progress.current][0] = newValue;
                        answerData?.setAnswers([...newAnswers]);
                      }
                    }
                  }}
                />
              </FormControl>

              <FormControl
                id="age"
                ml={isMobile ? '0px' : '56px'}
                w={isMobile ? '100%' : ''}
                mt={isMobile ? '16px' : '0px'}
              >
                <FormLabel color="white">What is your age?</FormLabel>
                <InputTwo
                  value={answerData?.answers[progress.current][1] || ''}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const numberReg = /^\d*$/;
                    if (numberReg.test(newValue) && newValue.length <= 2) {
                      const newAnswers = answerData?.answers;
                      if (!isUndefined(newAnswers)) {
                        if (newAnswers[progress.current][1] === undefined) {
                          newAnswers[progress.current][1] = newValue.toString();
                        }
                        newAnswers[progress.current][1] = newValue.toString();
                        answerData?.setAnswers([...newAnswers]);
                      }
                    }
                  }}
                />
              </FormControl>
            </Flex>

            <Flex className="Question-HelperText">
              {questionData &&
                questionData.sliders &&
                questionData?.sliders[0].context.help_text}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
  return <DescriptionText>404 question type not found</DescriptionText>;
};

const DescriptionText: React.FC<HeadingProps> = ({children, ...rest}) => {
  return (
    <Heading
      as="h1"
      fontFamily="Inter"
      fontStyle="normal"
      fontWeight="400"
      fontSize="24px"
      lineHeight="32px"
      letterSpacing="-0.011em"
      color="white"
      _focus={{
        boxShadow: '0 0 0 3px #ED00B9',
      }}
      {...rest}
    >
      {children}
    </Heading>
  );
};

const PrefaceText: React.FC<HeadingProps> = ({
  marginTop,
  marginBottom,
  children,
  ...rest
}) => {
  return (
    <Heading
      as="h3"
      mt={marginTop || '40px'}
      fontFamily="Inter"
      fontStyle="normal"
      fontWeight="500"
      fontSize="20px"
      lineHeight="28px"
      letterSpacing="-0.011em"
      color="white"
      mb={marginBottom || '80px'}
      _focus={{
        boxShadow: '0 0 0 3px #ED00B9',
      }}
      {...rest}
    >
      {children}
    </Heading>
  );
};

const InputOne: React.FC<InputProps> = ({value, onChange}) => {
  return (
    <Input
      background="#ffffff"
      borderRadius="2px"
      width="330px"
      height="56px"
      fontFamily="Inter"
      fontStyle="normal"
      fontWeight="500"
      fontSize="16px"
      lineHeight="24px"
      letterSpacing="-0.011em"
      color="#333333"
      padding="21px 16px"
      aria-label="Text Input Field. A through Z only. Enter your first name."
      aria-autocomplete="none"
      aria-required="true"
      contentEditable="true"
      placeholder="First name"
      type="text"
      value={value}
      onChange={onChange}
    />
  );
};

const InputTwo: React.FC<InputProps> = ({value, onChange}) => {
  return (
    <Input
      background="#ffffff"
      borderRadius="2px"
      width="170px"
      height="56px"
      fontFamily="Inter"
      fontStyle="normal"
      fontWeight="500"
      fontSize="16px"
      lineHeight="24px"
      letterSpacing="-0.011em"
      color="#333333"
      padding="21px 16px"
      aria-label="Text Input Field. Numbers only. Enter your age"
      aria-required="true"
      aria-autocomplete="none"
      contentEditable="true"
      placeholder="Age"
      type="text"
      value={value}
      onChange={onChange}
    />
  );
};

export default QuestionContainer;
