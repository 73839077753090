import {Image} from '@chakra-ui/image';
import './styles/styles.css';
import {useContext} from 'react';
import {Box, Flex, Text} from '@chakra-ui/layout';
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/slider';
import React from 'react';
import SliderAccompanyBox from '../../Assets/Slider-Accompany-Box.svg';
import SliderChevronIcon from '../../Assets/Slider-Chevron-Right.svg';
import SliderChevronCenter from '../../Assets/Slider-Center-Chevron.svg';
import SliderQuestionRED from '../../Assets/Slider-Question-Red.svg';
import SliderQuestionBLUE from '../../Assets/Slider-Question-Blue.svg';
import {useMediaQuery} from 'react-responsive';
import {AnswerContext} from '../../Context';
import {isUndefined} from 'lodash';

interface Props {
  progress: {
    total: number;
    current: number;
  };
  nextOptions: {
    doNextAfter: number;
    current: number;
  };
}

const pickUpFromState = (
  answerData: {
    answers: string[][];
    setAnswers: React.Dispatch<React.SetStateAction<string[][]>>;
  } | null,
  defaultValue: number,
  progress: {
    total: number;
    current: number;
  },
  nextOptions: {
    doNextAfter: number;
    current: number;
  }
) => {
  if (
    isUndefined(answerData) ||
    isUndefined(answerData?.answers[progress.current][nextOptions.current]) ||
    answerData?.answers[progress.current][nextOptions.current] === null
  ) {
    return defaultValue;
  }
  if (answerData) {
    if (answerData.answers[progress.current][nextOptions.current] === '') {
      return defaultValue;
    }
    return parseInt(
      answerData.answers[progress.current][nextOptions.current],
      10
    ) as number;
  }
  return defaultValue;
};

const SliderWithInput: React.FC<Props> = ({progress, nextOptions}) => {
  const answerData = useContext(AnswerContext);
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const isSmall = useMediaQuery({query: '(max-width: 400px)'});
  const value = pickUpFromState(answerData, 300, progress, nextOptions);
  const handleValueChange = (newValue: number) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current][nextOptions.current] === undefined) {
        newAnswers[progress.current][nextOptions.current] = newValue.toString();
      }
      newAnswers[progress.current][nextOptions.current] = newValue.toString();
      answerData?.setAnswers([...newAnswers]);
    }
  };
  const sliderLeft = (value / 2700) * 100;
  return (
    <Flex
      direction="column"
      maxW={isMobile ? (isSmall ? '280px' : '80vw') : '490px'}
    >
      <Flex position="relative">
        <Flex
          className="SliderAccompany"
          top="-40px"
          left={
            isMobile
              ? isSmall
                ? `calc(${sliderLeft}% - 92px)`
                : `calc(${sliderLeft}% - ${110 * 0.9}px)`
              : `calc(${sliderLeft}% - 115px)`
          }
          w="64px"
          h="31px"
        >
          <Image
            src={SliderAccompanyBox}
            alt="Slider"
            className="SliderAccompanyBox"
          />
          <Text className="SliderAccompanyText" color="blue.medium">
            {`$${value}`}
          </Text>
        </Flex>
        <Slider
          aria-label="Slider Moveable Left 300 US Dollars Right 3000 US Dollars"
          aria-valuetext={`${value} US Dollars`}
          aria-orientation="horizontal"
          aria-valuemin={300}
          aria-valuemax={3000}
          width={['280px', '400px', '600px']}
          min={300}
          max={3000}
          step={50}
          value={value}
          onChange={(newVal): void => {
            handleValueChange(newVal);
          }}
        >
          <SliderTrack
            tabIndex={-1}
            height="5px"
            borderRadius="0px"
            bg="grey.light"
          >
            <SliderFilledTrack bg="blue.semiLight" />
          </SliderTrack>

          <SliderThumb
            tabIndex={0}
            background="white"
            boxSize={8}
            backgroundImage={SliderChevronIcon}
            backgroundSize="24px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
          >
            <Box color="tomato" />
          </SliderThumb>
        </Slider>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" marginTop="10px">
        <Text className="NotationText">$300</Text>
        <Text className="NotationText">$3,000</Text>
      </Flex>
    </Flex>
  );
};

export const SliderWithImage: React.FC<Props> = ({progress, nextOptions}) => {
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const isSmall = useMediaQuery({query: '(max-width: 400px)'});
  const answerData = useContext(AnswerContext);
  const value = pickUpFromState(answerData, 50, progress, nextOptions);

  const handleValueChange = (newValue: number) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current][nextOptions.current] === undefined) {
        newAnswers[progress.current][nextOptions.current] = newValue.toString();
      }
      newAnswers[progress.current][nextOptions.current] = newValue.toString();
      answerData?.setAnswers([...newAnswers]);
    }
  };

  const outputAriaValueText = (value: number) => {
    if (value >= 50) {
      return `${value} More Similar`;
    } else {
      return `${value} Less Similar`;
    }
  };
  return (
    <Flex
      direction="column"
      maxW={isMobile ? (isSmall ? '80vw' : '350px') : '490px'}
    >
      <Flex marginBottom="20px" position="relative" justifyContent="center">
        <Flex h="110px" justifyContent="center">
          <Image
            src={SliderQuestionBLUE}
            alt="Future you"
            position="absolute"
            left={
              isSmall
                ? `${value * 0.5 + 35}px`
                : isMobile
                ? `${value * 0.8 + 35}px`
                : `${value + 100}px`
            }
            top="17px"
          />
          <Image
            src={SliderQuestionRED}
            alt="Current you"
            right={
              isSmall
                ? `${value * 0.5 + 35}px`
                : isMobile
                ? `${value * 0.8 + 35}px`
                : `${value + 100}px`
            }
            position="absolute"
          />
        </Flex>
      </Flex>
      <Flex position="relative">
        <Slider
          focusThumbOnChange={false}
          width={['280px', '400px', '600px']}
          min={0}
          max={100}
          value={value}
          aria-label="Slider Moveable Left 0 Less Similar Right 100 More Similar"
          aria-valuetext={outputAriaValueText(value)}
          aria-orientation="horizontal"
          aria-valuemin={0}
          aria-valuemax={100}
          onChange={(newVal): void => {
            handleValueChange(newVal);
          }}
        >
          <SliderTrack
            tabIndex={-1}
            height="5px"
            borderRadius="0px"
            bg="grey.light"
          >
            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            tabIndex={0}
            background="transparent"
            boxSize={10}
            backgroundImage={SliderChevronCenter}
            backgroundSize="36px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
          >
            <Box color="tomato" />
          </SliderThumb>
        </Slider>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" marginTop="10px">
        <Text className="NotationText-2">Less similar</Text>
        <Text className="NotationText-2">More similar</Text>
      </Flex>
    </Flex>
  );
};

export const SliderType3: React.FC<Props> = ({progress, nextOptions}) => {
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const isSmall = useMediaQuery({query: '(max-width: 400px)'});
  const answerData = useContext(AnswerContext);
  const value = pickUpFromState(answerData, 5, progress, nextOptions);

  const handleValueChange = (newValue: number) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current][nextOptions.current] === undefined) {
        newAnswers[progress.current][nextOptions.current] = newValue.toString();
      }
      newAnswers[progress.current][nextOptions.current] = newValue.toString();
      answerData?.setAnswers([...newAnswers]);
    }
  };

  const outputAriaValueText = (value: number) => {
    if (value >= 5) {
      return `${
        value * 10
      } Definitely less job security with a large pay increase.`;
    } else {
      return `${
        value * 10
      } Definitely more job security with a small pay increase.`;
    }
  };

  return (
    <Flex
      direction="column"
      maxW={isMobile ? (isSmall ? '85vw' : '80vw') : '490px'}
    >
      <Flex position="relative">
        <Slider
          width={['280px', '400px', '600px']}
          min={0}
          step={1}
          max={10}
          value={value}
          onChange={(newVal): void => {
            handleValueChange(newVal);
          }}
          aria-label="Slider. Moveable. Left. 0 Definitely more job security with a small pay increase. Right. 10 Definitely less job security with a large pay increase."
          aria-valuetext={outputAriaValueText(value)}
          aria-orientation="horizontal"
          aria-valuemin={0}
          aria-valuemax={10}
        >
          <SliderTrack
            tabIndex={-1}
            height="5px"
            borderRadius="0px"
            bg="transparent"
          >
            <Flex>
              <Flex
                borderRadius="4px"
                mr="4px"
                bg="#C4C4C4"
                width="94px"
                h="5px"
              ></Flex>
              <Flex
                borderRadius="4px"
                mr="4px"
                bg="#C4C4C4"
                width="94px"
                h="5px"
              ></Flex>
              <Flex
                borderRadius="4px"
                mr="4px"
                bg="#C4C4C4"
                width="94px"
                h="5px"
              ></Flex>
              <Flex
                borderRadius="4px"
                mr="4px"
                bg="#C4C4C4"
                width="94px"
                h="5px"
              ></Flex>
              <Flex
                borderRadius="4px"
                mr="4px"
                bg="#C4C4C4"
                width="94px"
                h="5px"
              ></Flex>
            </Flex>

            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            tabIndex={0}
            background="#00A3E0"
            boxSize={8}
            backgroundSize="36px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
          >
            <Box color="blue.medium" />
          </SliderThumb>
        </Slider>
      </Flex>
      <Flex justifyContent="space-between" marginTop="10px">
        <div className="NotationText-3">
          Definitely<p className="NotationText-3-blue"> more job security</p>{' '}
          with a<p className="NotationText-3-blue"> small pay increase</p>
        </div>
        {/* <Text className="NotationText-3">Not sure</Text> */}
        <div className="NotationText-3" style={{textAlign: 'right'}}>
          Definitely<p className="NotationText-3-blue"> less job security</p>{' '}
          with a<p className="NotationText-3-blue"> big pay increase</p>
        </div>
      </Flex>
    </Flex>
  );
};

export const SliderType4: React.FC<Props> = ({progress, nextOptions}) => {
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const isSmall = useMediaQuery({query: '(max-width: 400px)'});
  const answerData = useContext(AnswerContext);
  const value = pickUpFromState(answerData, 0, progress, nextOptions);

  const handleValueChange = (newValue: number) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current][nextOptions.current] === undefined) {
        newAnswers[progress.current][nextOptions.current] = newValue.toString();
      }
      newAnswers[progress.current][nextOptions.current] = newValue.toString();
      answerData?.setAnswers([...newAnswers]);
    }
  };
  return (
    <Flex
      direction="column"
      maxW={isMobile ? (isSmall ? '280px' : '280px') : '490px'}
    >
      <Flex position="relative">
        <Slider
          width={['280px', '400px', '600px']}
          min={0}
          max={12}
          aria-valuetext={`${value} Month's Income`}
          aria-label="Slider. Moveable. Left. 0 Months Income. Right. 12 Months Income."
          aria-orientation="horizontal"
          aria-valuemin={0}
          aria-valuemax={12}
          value={value}
          onChange={(newVal): void => {
            handleValueChange(newVal);
          }}
        >
          <Flex
            className="SliderAccompany"
            top="-40px"
            left={`calc(${(value / 12) * 100}% - 60px)`}
            w="64px"
            h="31px"
          >
            <Image
              src={SliderAccompanyBox}
              alt="Slider"
              className="SliderAccompanyBox"
            />
            <Text className="SliderAccompanyText" color="blue.medium">
              {`${value} month's`}
            </Text>
          </Flex>
          <SliderTrack
            tabIndex={-1}
            height="5px"
            borderRadius="4px"
            bg="#C4C4C4;"
          >
            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            tabIndex={0}
            background="#00A3E0"
            boxSize={8}
            backgroundSize="36px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
          >
            <Box color="blue.medium" />
          </SliderThumb>
        </Slider>
      </Flex>
      <Flex justifyContent="space-between" marginTop="10px">
        <Text className="NotationText-2">0 month’s income</Text>
        <Text className="NotationText-2">12 month’s income</Text>
      </Flex>
    </Flex>
  );
};

export const SliderType5: React.FC<Props> = ({progress, nextOptions}) => {
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const isSmall = useMediaQuery({query: '(max-width: 400px)'});
  const answerData = useContext(AnswerContext);
  const value = pickUpFromState(answerData, 40, progress, nextOptions);

  const handleValueChange = (newValue: number) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current][nextOptions.current] === undefined) {
        newAnswers[progress.current][nextOptions.current] = newValue.toString();
      }
      newAnswers[progress.current][nextOptions.current] = newValue.toString();
      answerData?.setAnswers([...newAnswers]);
    }
  };

  const outputAriaValueText = (value: number) => {
    if (value >= 40) {
      return `${value}% Strongly Agree`;
    } else {
      return `${value}% Strongly Disagree`;
    }
  };
  return (
    <Flex
      direction="column"
      maxW={isMobile ? (isSmall ? '85vw' : '85vw') : '490px'}
    >
      <Flex position="relative">
        <Slider
          width={['280px', '400px', '600px']}
          min={10}
          max={70}
          value={value}
          aria-valuetext={outputAriaValueText(value)}
          aria-label="Slider. Moveable. Left. 10 Strongly Agree. Right. 70 Strongly Disagree."
          aria-orientation="horizontal"
          aria-valuemin={10}
          aria-valuemax={70}
          onChange={(newVal): void => {
            handleValueChange(newVal);
          }}
        >
          <SliderTrack
            tabIndex={-1}
            height="5px"
            borderRadius="4px"
            bg="#C4C4C4;"
          >
            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            tabIndex={0}
            background="#00A3E0"
            boxSize={8}
            backgroundSize="36px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
          >
            <Box color="blue.medium" />
          </SliderThumb>
        </Slider>
      </Flex>
      <Flex justifyContent="space-between" marginTop="10px">
        <Text className="NotationText-2">Strongly Agree</Text>
        <Text className="NotationText-2">Strongly Disagree</Text>
      </Flex>
    </Flex>
  );
};

export const SliderType6: React.FC<Props> = ({progress, nextOptions}) => {
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const isSmall = useMediaQuery({query: '(max-width: 400px)'});
  const answerData = useContext(AnswerContext);
  const value = pickUpFromState(answerData, 50, progress, nextOptions);

  const handleValueChange = (newValue: number) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current][nextOptions.current] === undefined) {
        newAnswers[progress.current][nextOptions.current] = newValue.toString();
      }
      newAnswers[progress.current][nextOptions.current] = newValue.toString();
      answerData?.setAnswers([...newAnswers]);
    }
  };

  const outputAriaValueText = (value: number) => {
    if (value >= 50) {
      return `${value}% Prefer to be hands on.`;
    } else {
      return `${value}% Prefer an expert to handle it`;
    }
  };
  return (
    <Flex
      direction="column"
      maxW={isMobile ? (isSmall ? '85vw' : '85vw') : '490px'}
    >
      <Flex position="relative">
        <Slider
          width={['280px', '400px', '600px']}
          min={0}
          max={100}
          value={value}
          aria-valuetext={outputAriaValueText(value)}
          aria-label="Slider. Moveable. Left. 0 Prefer to be hands on. Right. 100 Prefer an expert to handle it"
          aria-orientation="horizontal"
          aria-valuemin={0}
          aria-valuemax={100}
          onChange={(newVal): void => {
            handleValueChange(newVal);
          }}
        >
          <SliderTrack
            tabIndex={-1}
            height="5px"
            borderRadius="4px"
            bg="#C4C4C4;"
          >
            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            tabIndex={0}
            background="#00A3E0"
            boxSize={8}
            backgroundSize="36px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
          >
            <Box color="blue.medium" />
          </SliderThumb>
        </Slider>
      </Flex>
      <Flex justifyContent="space-between" marginTop="10px">
        <Text className="NotationText-2">Prefer to be hands on</Text>
        <Text className="NotationText-2">Prefer an expert to handle it</Text>
      </Flex>
    </Flex>
  );
};

export const SliderType7: React.FC<Props> = ({progress, nextOptions}) => {
  const isMobile = useMediaQuery({query: '(max-width: 600px)'});
  const isSmall = useMediaQuery({query: '(max-width: 400px)'});
  const answerData = useContext(AnswerContext);
  const value = pickUpFromState(answerData, 50, progress, nextOptions);

  const handleValueChange = (newValue: number) => {
    const newAnswers = answerData?.answers;
    if (!isUndefined(newAnswers)) {
      if (newAnswers[progress.current][nextOptions.current] === undefined) {
        newAnswers[progress.current][nextOptions.current] = newValue.toString();
      }
      newAnswers[progress.current][nextOptions.current] = newValue.toString();
      answerData?.setAnswers([...newAnswers]);
    }
  };

  const outputAriaValueText = (value: number) => {
    if (value >= 50) {
      return `${value}% Prefer to be hands on.`;
    } else {
      return `${value}% Prefer an expert to handle it`;
    }
  };
  return (
    <Flex
      direction="column"
      maxW={isMobile ? (isSmall ? '85vw' : '85vw') : '490px'}
    >
      <Flex position="relative">
        <Slider
          width={['280px', '400px', '600px']}
          min={0}
          max={100}
          value={value}
          aria-valuetext={outputAriaValueText(value)}
          aria-label="Slider. Moveable. Left. 0 Find it interesting. Right. 100 Oh no! Find it boring."
          aria-orientation="horizontal"
          aria-valuemin={0}
          aria-valuemax={100}
          onChange={(newVal): void => {
            handleValueChange(newVal);
          }}
        >
          <SliderTrack
            tabIndex={-1}
            height="5px"
            borderRadius="4px"
            bg="#C4C4C4;"
          >
            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            tabIndex={0}
            background="#00A3E0"
            boxSize={8}
            backgroundSize="36px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
          >
            <Box color="blue.medium" />
          </SliderThumb>
        </Slider>
      </Flex>
      <Flex justifyContent="space-between" marginTop="10px">
        <Text className="NotationText-2">Find it interesting</Text>
        <Text className="NotationText-2">Oh no! Find it boring</Text>
      </Flex>
    </Flex>
  );
};

export default SliderWithInput;
