// noinspection DuplicatedCode

import React, {useEffect, useRef, useState} from 'react';
import {Flex, Image, Progress, Text, VisuallyHidden} from '@chakra-ui/react';
import './styles/mobile.css';
import Section2Arrow from '../../Assets/Mobile-Report-Section2-Arrow.svg';
import Section2Bullet from '../../Assets/Mobile-Report-Section2-Bullet.svg';
import AccordionExtra from '../../Assets/Report-Section3-Accordion-Extra.svg';
import AccordionExtra2 from '../../Assets/Report-AccordionExtra2.svg';
import SyntoniqLogo from '../../Assets/Syntoniq-logo.svg';
import ChevronUpDown from '../../Assets/Report-Section4-Chevron-Down.svg';
import {ReportData} from '../../type2';
import Section1Bg from '../Section1Image';
import VideoPlay from '../../Assets/Report-Section3-Video.svg';
import BiometricPicture from '../../Assets/Report-Section3-Biometric2.svg';
import ProfilePictureBlue from '../../Assets/Report-Section3-ProfileBlue.svg';
import AorAn from '../AorAn';
import axios from 'axios';
import {ASSESSMENT_POST_API_URL} from '../../Data';
interface Props {
  reportData: ReportData;
  assessmentID: string;
}
const MobileReport: React.FC<Props> = ({reportData, assessmentID}) => {
  const [accordionTab, setAccordionTab] = useState('Future Focus');
  const [section3Accordion, setSection3Accordion] = useState(() => {
    if (
      reportData.report.take_action.advice_first ||
      (!reportData.report.take_action.advice_first &&
        !reportData.report.take_action.education_first)
    ) {
      return 1;
    }
    return 2;
  });

  useEffect(() => {
    if (
      reportData.report.take_action.advice_first ||
      (reportData.report.take_action.advice_first === false &&
        reportData.report.take_action.education_first === false)
    ) {
      setSection3Accordion(1);
    } else {
      setSection3Accordion(2);
    }
  }, [
    reportData.report.take_action.advice_first,
    reportData.report.take_action.education_first,
  ]);
  const LinkClick = (url: string) => {
    const payload = {
      url: url,
    };
    const PostingUrl = ASSESSMENT_POST_API_URL + `/${assessmentID}/ctas`;
    axios
      .post(PostingUrl, payload)
      .then(() => {})
      .catch((error) => {
        console.warn(error);
      });
  };
  const DetailsRef = useRef<HTMLDivElement>(null);
  return (
    <Flex className="Mobile-Report-Container">
      <Flex className="Mobile-Report-Section1">
        <Flex
          className="Mobile-Report-Section1-Bg-Image"
          bgImage={Section1Bg(reportData.report.header_image, 'mobile')}
        >
          <Image
            src={Section1Bg(reportData.report.header_image, 'mobile')}
            alt="Background"
            maxW="100%"
            maxH="100%"
            visibility="hidden"
          />
        </Flex>
        <Flex className="Mobile-Report-Section1-Heading" zIndex="1">
          <div className="Mobile-Report-Section1-HeadingMain">
            <p className="Mobile-Report-Section1-HeadingTitleMain">
              {reportData.client.first_name},
            </p>
          </div>
          <div className="Mobile-Report-Section1-HeadingMainSub">
            You are{` ${AorAn(reportData.report.scores.persona)}`}
          </div>
          <Text className="Mobile-Report-Section1-HeadingSub">
            {reportData.report.scores.persona}
          </Text>
          <Text className="Mobile-Report-Section1-HeadingDescription">
            {reportData.report.scores.persona_description}
          </Text>
        </Flex>
        <Flex w="100%" zIndex="1">
          <div className="Mobile-Report-Section1-Divider"></div>
        </Flex>

        <Flex className="Mobile-Report-Section1-Description" zIndex="1">
          <Text className="Mobile-Report-Section1-DescriptionTitle">
            ABOUT THIS REPORT
          </Text>
          <Text className="Mobile-Report-Section1-DescriptionSub">
            Our Behavior Personality Report is designed to help you uncover the
            key psychological factors that can influence your financial
            decision-making process.
          </Text>
        </Flex>
        <Flex className="Mobile-Report-Section1-CardsContainer" zIndex="1">
          <Text className="Mobile-Report-Section1-CardsContainerTitle">
            Factors influencing your financial decision-making
          </Text>
          <Flex className="Mobile-Report-Section1-CardContainer">
            <Flex className="Mobile-Report-Section1-Card">
              <Text className="Mobile-Report-Section1-CardTitle">
                Future Focus
              </Text>
              <Flex direction="column">
                <div className="Mobile-Report-Section1-CardScore">
                  {reportData.report.scores.focus.value}
                  <VisuallyHidden>Out of 100</VisuallyHidden>
                  <p
                    className="Mobile-Report-Section1-Card100"
                    aria-hidden={true}
                  >
                    &nbsp;/ 100
                  </p>
                </div>
                <Progress
                  value={reportData.report.scores.focus.value}
                  w="100%"
                  className="Report-Progress1"
                  background="#004C95"
                  borderRadius="100px"
                  aria-hidden={true}
                />
              </Flex>
            </Flex>

            <Flex className="Mobile-Report-Section1-Card">
              <Text className="Mobile-Report-Section1-CardTitle">
                Tendency to Avoid Risk
              </Text>
              <Flex direction="column">
                <div className="Mobile-Report-Section1-CardScore">
                  {reportData.report.scores.risk.value}
                  <VisuallyHidden>Out of 100</VisuallyHidden>
                  <p
                    className="Mobile-Report-Section1-Card100"
                    aria-hidden={true}
                  >
                    &nbsp;/ 100
                  </p>
                </div>
                <Progress
                  value={reportData.report.scores.risk.value}
                  w="100%"
                  className="Report-Progress2"
                  background="#004C95"
                  borderRadius="100px"
                  aria-hidden={true}
                />
              </Flex>
            </Flex>

            <Flex className="Mobile-Report-Section1-Card">
              <Text className="Mobile-Report-Section1-CardTitle">
                Desire for Certainty
              </Text>
              <Flex direction="column">
                <div className="Mobile-Report-Section1-CardScore">
                  {reportData.report.scores.certainty.value}
                  <VisuallyHidden>Out of 100</VisuallyHidden>
                  <p
                    className="Mobile-Report-Section1-Card100"
                    aria-hidden={true}
                  >
                    &nbsp;/ 100
                  </p>
                </div>
                <Progress
                  value={reportData.report.scores.certainty.value}
                  w="100%"
                  className="Report-Progress3"
                  background="#004C95"
                  borderRadius="100px"
                  aria-hidden={true}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          className="Mobile-Report-Section1-Bottom-Button"
          aria-label="Scroll down button"
          onClick={() => {
            if (DetailsRef != null && DetailsRef.current != null) {
              DetailsRef.current.scrollIntoView();
            }
          }}
          tabIndex={0}
        >
          <div className="container">
            <div className="chevron"></div>
            <div className="chevron"></div>
            <div className="chevron"></div>
          </div>
        </Flex>
      </Flex>
      <div ref={DetailsRef}>
        <Flex className="Mobile-Report-Section2">
          <Flex className="Mobile-Report-Section2-Arrow">
            <Image src={Section2Arrow} alt="Guidance" />
          </Flex>
          <Text className="Mobile-Report-Section2-Title">Your Journey</Text>

          <Flex className="Mobile-Report-Section2-CardsContainer">
            <Flex className="Mobile-Report-Section2-Card">
              <Text className="Mobile-Report-Section2-CardTitle">
                {reportData.report.your_journey[0].title}
              </Text>
              <Text className="Mobile-Report-Section2-CardSub">
                {reportData.report.your_journey[0].subtitle}
              </Text>
              <Flex direction="column" mt="20px">
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    mt="7px"
                    mr="8px"
                    src={Section2Bullet}
                    alt="Bullet point"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[0].bullets[0]}
                  </Text>
                </Flex>
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    src={Section2Bullet}
                    alt="Bullet point"
                    mt="7px"
                    mr="8px"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[0].bullets[1]}
                  </Text>
                </Flex>
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    src={Section2Bullet}
                    alt="Bullet point"
                    mt="7px"
                    mr="8px"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[0].bullets[2]}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex className="Mobile-Report-Section2-Card">
              <Text className="Mobile-Report-Section2-CardTitle">
                {reportData.report.your_journey[1].title}
              </Text>
              <Text className="Mobile-Report-Section2-CardSub">
                {reportData.report.your_journey[1].subtitle}
              </Text>
              <Flex direction="column" mt="20px">
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    mt="7px"
                    mr="8px"
                    src={Section2Bullet}
                    alt="Bullet point"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[1].bullets[0]}
                  </Text>
                </Flex>
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    src={Section2Bullet}
                    alt="Bullet point"
                    mt="7px"
                    mr="8px"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[1].bullets[1]}
                  </Text>
                </Flex>
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    src={Section2Bullet}
                    alt="Bullet point"
                    mt="7px"
                    mr="8px"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[1].bullets[2]}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex className="Mobile-Report-Section2-Card">
              <Text className="Mobile-Report-Section2-CardTitle">
                {reportData.report.your_journey[2].title}
              </Text>
              <Text className="Mobile-Report-Section2-CardSub">
                {reportData.report.your_journey[2].subtitle}
              </Text>
              <Flex direction="column" mt="20px">
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    mt="7px"
                    mr="8px"
                    src={Section2Bullet}
                    alt="Bullet point"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[2].bullets[0]}
                  </Text>
                </Flex>
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    src={Section2Bullet}
                    alt="Bullet point"
                    mt="7px"
                    mr="8px"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[2].bullets[1]}
                  </Text>
                </Flex>
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    src={Section2Bullet}
                    alt="Bullet point"
                    mt="7px"
                    mr="8px"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[2].bullets[2]}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex className="Mobile-Report-Section2-Card">
              <Text className="Mobile-Report-Section2-CardTitle">
                {reportData.report.your_journey[3].title}
              </Text>
              <Text className="Mobile-Report-Section2-CardSub">
                {reportData.report.your_journey[3].subtitle}
              </Text>
              <Flex direction="column" mt="20px">
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    mt="7px"
                    mr="8px"
                    src={Section2Bullet}
                    alt="Bullet point"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[3].bullets[0]}
                  </Text>
                </Flex>
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    src={Section2Bullet}
                    alt="Bullet point"
                    mt="7px"
                    mr="8px"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[3].bullets[1]}
                  </Text>
                </Flex>
                <Flex>
                  <Image
                    w="12px"
                    h="12px"
                    src={Section2Bullet}
                    alt="Bullet point"
                    mt="7px"
                    mr="8px"
                  />
                  <Text className="Mobile-Report-Section2-CardBullet">
                    {reportData.report.your_journey[3].bullets[2]}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </div>
      <Flex className="Mobile-Report-Section3">
        <Flex direction="column" padding="0 15px">
          <Text className="Mobile-Report-Section3-Heading">START HERE</Text>
          <Text className="Mobile-Report-Section3-PostHeading">
            Choose one of the following below:
          </Text>
        </Flex>
        <Flex className="Mobile-Report-Section3-Accordion" role="radiogroup">
          {reportData.report.take_action.advice_first ? (
            <>
              <Flex direction="column">
                <Flex
                  className="Mobile-Report-Section3-Item"
                  onClick={() => {
                    setSection3Accordion((prev) => {
                      if (prev === 1) {
                        return 0;
                      }
                      return 1;
                    });
                  }}
                  id="guidance"
                  tabIndex={0}
                  role="radio"
                  aria-checked="true"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || 'Space') {
                      setSection3Accordion((prev) => {
                        if (prev === 1) {
                          return 0;
                        }
                        return 1;
                      });
                      e.currentTarget.setAttribute(
                        'aria-checked',
                        String(true)
                      );
                      document
                        .getElementById('learn')
                        ?.setAttribute('aria-checked', String(false));
                    }
                  }}
                >
                  <Flex className="Mobile-Report-Section3-Accordion-circle">
                    1
                  </Flex>
                  <Text className="Mobile-Report-Section3-Accordion-Text">
                    Get Guidance
                  </Text>
                  <Image
                    ml="auto"
                    w="20px"
                    src={ChevronUpDown}
                    transform={
                      section3Accordion === 1
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                    }
                  />
                </Flex>

                {section3Accordion === 1 && (
                  <Flex flex="2" className="Mobile-Report-Section3-Open">
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                    >
                      <Flex direction="column">
                        <Text className="Report-Section3-Right-Heading">
                          Get Guidance
                        </Text>
                      </Flex>
                      <Flex>
                        <Image src={AccordionExtra} alt="Advice" />
                      </Flex>
                    </Flex>
                    <Flex
                      mt="28px"
                      direction="column-reverse"
                      alignItems="center"
                    >
                      <a
                        className="Mobile-Report-Section3-Human"
                        href={reportData.report.cta.advice.human}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.advice.human);
                        }}
                        tabIndex={0}
                      >
                        <Image
                          src={ProfilePictureBlue}
                          alt="Profile"
                          h="127px"
                          w="127px"
                        />
                        <Text className="Report-Section3-HumanText">Human</Text>
                      </a>
                      <a
                        className="Mobile-Report-Section3-Biometric"
                        href={reportData.report.cta.advice.digital}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.advice.digital);
                        }}
                        tabIndex={0}
                      >
                        <Image
                          src={BiometricPicture}
                          alt="Biometric"
                          h="127px"
                          w="127px"
                        />
                        <Text className="Report-Section3-BiometricText">
                          Digital
                        </Text>
                      </a>
                    </Flex>
                  </Flex>
                )}
              </Flex>

              <Flex direction="column">
                <Flex
                  className="Mobile-Report-Section3-Item"
                  onClick={() => {
                    setSection3Accordion((prev) => {
                      if (prev === 2) {
                        return 0;
                      }
                      return 2;
                    });
                  }}
                  id="learn"
                  tabIndex={0}
                  role="radio"
                  aria-checked="false"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || 'Space') {
                      setSection3Accordion((prev) => {
                        if (prev === 2) {
                          return 0;
                        }
                        return 2;
                      });
                      e.currentTarget.setAttribute(
                        'aria-checked',
                        String(true)
                      );
                      document
                        .getElementById('guidance')
                        ?.setAttribute('aria-checked', String(false));
                    }
                  }}
                >
                  <Flex className="Mobile-Report-Section3-Accordion-circle">
                    2
                  </Flex>
                  <Text className="Mobile-Report-Section3-Accordion-Text">
                    Learn More
                  </Text>
                  <Image
                    ml="auto"
                    w="20px"
                    src={ChevronUpDown}
                    transform={
                      section3Accordion === 2
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                    }
                  />
                </Flex>
                {section3Accordion === 2 && (
                  <Flex flex="2" className="Mobile-Report-Section3-Open">
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                    >
                      <Flex direction="column">
                        <Text
                          className="Report-Section3-Right-Heading"
                          tabIndex={0}
                        >
                          Learn More
                        </Text>
                      </Flex>
                      <Flex tabIndex={0}>
                        <Image src={AccordionExtra2} alt="Advice" />
                      </Flex>
                    </Flex>
                    <Flex
                      mt="28px"
                      direction="column-reverse"
                      alignItems="center"
                      w="100%"
                    >
                      <a
                        href={reportData.report.cta.education.video}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.video);
                        }}
                        className="Mobile-Report-Section3-VideoPlace"
                        tabIndex={0}
                      >
                        <Image src={VideoPlay} opacity="0.8" alt="play" />
                      </a>
                    </Flex>

                    <Flex justifyContent="space-between">
                      <a
                        className="Report-Section3-Button2"
                        href={reportData.report.cta.education.brochure}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.brochure);
                        }}
                        tabIndex={0}
                      >
                        Diversified Income
                      </a>
                      <a
                        className="Report-Section3-Button2"
                        href={reportData.report.cta.education.article}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.article);
                        }}
                        tabIndex={0}
                      >
                        Learn Article
                      </a>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </>
          ) : reportData.report.take_action.education_first ? (
            <>
              <Flex direction="column">
                <Flex
                  className="Mobile-Report-Section3-Item"
                  onClick={() => {
                    setSection3Accordion((prev) => {
                      if (prev === 2) {
                        return 0;
                      }
                      return 2;
                    });
                  }}
                  id="learn"
                  tabIndex={0}
                  role="radio"
                  aria-checked="true"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || 'Space') {
                      setSection3Accordion((prev) => {
                        if (prev === 2) {
                          return 0;
                        }
                        return 2;
                      });
                      e.currentTarget.setAttribute(
                        'aria-checked',
                        String(true)
                      );
                      document
                        .getElementById('guidance')
                        ?.setAttribute('aria-checked', String(false));
                    }
                  }}
                >
                  <Flex className="Mobile-Report-Section3-Accordion-circle">
                    1
                  </Flex>
                  <Text className="Mobile-Report-Section3-Accordion-Text">
                    Learn More
                  </Text>
                  <Image
                    ml="auto"
                    w="20px"
                    src={ChevronUpDown}
                    transform={
                      section3Accordion === 2
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                    }
                  />
                </Flex>
                {section3Accordion === 2 && (
                  <Flex flex="2" className="Mobile-Report-Section3-Open">
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                    >
                      <Flex direction="column">
                        <Text
                          className="Report-Section3-Right-Heading"
                          tabIndex={0}
                        >
                          Learn More
                        </Text>
                      </Flex>
                      <Flex>
                        <Image src={AccordionExtra2} alt="Learn More" />
                      </Flex>
                    </Flex>
                    <Flex
                      mt="28px"
                      direction="column-reverse"
                      alignItems="center"
                      w="100%"
                    >
                      <a
                        href={reportData.report.cta.education.video}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.video);
                        }}
                        className="Mobile-Report-Section3-VideoPlace"
                        tabIndex={0}
                      >
                        <Image src={VideoPlay} opacity="0.8" alt="play" />
                      </a>
                    </Flex>

                    <Flex justifyContent="space-between">
                      <a
                        className="Report-Section3-Button2"
                        href={reportData.report.cta.education.brochure}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.brochure);
                        }}
                        tabIndex={0}
                      >
                        Diversified Income
                      </a>
                      <a
                        className="Report-Section3-Button2"
                        href={reportData.report.cta.education.article}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.article);
                        }}
                        tabIndex={0}
                      >
                        Learn Article
                      </a>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex direction="column">
                <Flex
                  className="Mobile-Report-Section3-Item"
                  onClick={() => {
                    setSection3Accordion((prev) => {
                      if (prev === 1) {
                        return 0;
                      }
                      return 1;
                    });
                  }}
                  id="guidance"
                  tabIndex={0}
                  role="radio"
                  aria-checked="false"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || 'Space') {
                      setSection3Accordion((prev) => {
                        if (prev === 1) {
                          return 0;
                        }
                        return 1;
                      });
                      e.currentTarget.setAttribute(
                        'aria-checked',
                        String(true)
                      );
                      document
                        .getElementById('learn')
                        ?.setAttribute('aria-checked', String(false));
                    }
                  }}
                >
                  <Flex className="Mobile-Report-Section3-Accordion-circle">
                    2
                  </Flex>
                  <Text className="Mobile-Report-Section3-Accordion-Text">
                    Get Guidance
                  </Text>
                  <Image
                    ml="auto"
                    w="20px"
                    src={ChevronUpDown}
                    transform={
                      section3Accordion === 1
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                    }
                  />
                </Flex>

                {section3Accordion === 1 && (
                  <Flex flex="2" className="Mobile-Report-Section3-Open">
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                    >
                      <Flex direction="column">
                        <Text
                          className="Report-Section3-Right-Heading"
                          tabIndex={0}
                        >
                          Get Guidance
                        </Text>
                      </Flex>
                      <Flex tabIndex={0}>
                        <Image src={AccordionExtra} alt="Advice" />
                      </Flex>
                    </Flex>
                    <Flex
                      mt="28px"
                      direction="column-reverse"
                      alignItems="center"
                    >
                      <a
                        className="Mobile-Report-Section3-Human"
                        href={reportData.report.cta.advice.human}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.advice.human);
                        }}
                        tabIndex={0}
                      >
                        <Image
                          src={ProfilePictureBlue}
                          alt="Profile"
                          h="127px"
                          w="127px"
                        />
                        <Text className="Report-Section3-HumanText">Human</Text>
                      </a>
                      <a
                        className="Mobile-Report-Section3-Biometric"
                        href={reportData.report.cta.advice.digital}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.advice.digital);
                        }}
                        tabIndex={0}
                      >
                        <Image
                          src={BiometricPicture}
                          alt="Biometric"
                          h="127px"
                          w="127px"
                        />
                        <Text className="Report-Section3-BiometricText">
                          Digital
                        </Text>
                      </a>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </>
          ) : (
            <>
              <Flex direction="column">
                <Flex
                  className="Mobile-Report-Section3-Item"
                  onClick={() => {
                    setSection3Accordion((prev) => {
                      if (prev === 1) {
                        return 0;
                      }
                      return 1;
                    });
                  }}
                  id="guidance"
                  tabIndex={0}
                  role="radio"
                  aria-checked="false"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || 'Space') {
                      setSection3Accordion((prev) => {
                        if (prev === 1) {
                          return 0;
                        }
                        return 1;
                      });
                      e.currentTarget.setAttribute(
                        'aria-checked',
                        String(true)
                      );
                      document
                        .getElementById('learn')
                        ?.setAttribute('aria-checked', String(false));
                    }
                  }}
                >
                  <Flex className="Mobile-Report-Section3-Accordion-circle">
                    1
                  </Flex>
                  <Text className="Mobile-Report-Section3-Accordion-Text">
                    Get Guidance
                  </Text>
                  <Image
                    ml="auto"
                    w="20px"
                    src={ChevronUpDown}
                    transform={
                      section3Accordion === 1
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                    }
                  />
                </Flex>

                {section3Accordion === 1 && (
                  <Flex flex="2" className="Mobile-Report-Section3-Open">
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                    >
                      <Flex direction="column">
                        <Text
                          className="Report-Section3-Right-Heading"
                          tabIndex={0}
                        >
                          Get Guidance
                        </Text>
                      </Flex>
                      <Flex tabIndex={0}>
                        <Image src={AccordionExtra} alt="Advice" />
                      </Flex>
                    </Flex>
                    <Flex
                      mt="28px"
                      direction="column-reverse"
                      alignItems="center"
                    >
                      <a
                        className="Mobile-Report-Section3-Human"
                        href={reportData.report.cta.advice.human}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.advice.human);
                        }}
                        tabIndex={0}
                      >
                        <Image
                          src={ProfilePictureBlue}
                          alt="Profile"
                          h="127px"
                          w="127px"
                        />
                        <Text className="Report-Section3-HumanText">Human</Text>
                      </a>
                      <a
                        className="Mobile-Report-Section3-Biometric"
                        href={reportData.report.cta.advice.digital}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.advice.digital);
                        }}
                        tabIndex={0}
                      >
                        <Image
                          src={BiometricPicture}
                          alt="Biometric"
                          h="127px"
                          w="127px"
                        />
                        <Text className="Report-Section3-BiometricText">
                          Digital
                        </Text>
                      </a>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex direction="column">
                <Flex
                  className="Mobile-Report-Section3-Item"
                  onClick={() => {
                    setSection3Accordion((prev) => {
                      if (prev === 2) {
                        return 0;
                      }
                      return 2;
                    });
                  }}
                  id="learn"
                  tabIndex={0}
                  role="radio"
                  aria-checked="true"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || 'Space') {
                      setSection3Accordion((prev) => {
                        if (prev === 2) {
                          return 0;
                        }
                        return 2;
                      });
                      e.currentTarget.setAttribute(
                        'aria-checked',
                        String(true)
                      );
                      document
                        .getElementById('guidance')
                        ?.setAttribute('aria-checked', String(false));
                    }
                  }}
                >
                  <Flex className="Mobile-Report-Section3-Accordion-circle">
                    2
                  </Flex>
                  <Text className="Mobile-Report-Section3-Accordion-Text">
                    Learn More
                  </Text>
                  <Image
                    ml="auto"
                    w="20px"
                    src={ChevronUpDown}
                    transform={
                      section3Accordion === 2
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                    }
                  />
                </Flex>
                {section3Accordion === 2 && (
                  <Flex flex="2" className="Mobile-Report-Section3-Open">
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                    >
                      <Flex direction="column">
                        <Text
                          className="Report-Section3-Right-Heading"
                          tabIndex={0}
                        >
                          Learn More
                        </Text>
                      </Flex>
                      <Flex tabIndex={0}>
                        <Image src={AccordionExtra2} alt="Learn More" />
                      </Flex>
                    </Flex>
                    <Flex
                      mt="28px"
                      direction="column-reverse"
                      alignItems="center"
                      w="100%"
                    >
                      <a
                        href={reportData.report.cta.education.video}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.video);
                        }}
                        className="Mobile-Report-Section3-VideoPlace"
                        tabIndex={0}
                      >
                        <Image src={VideoPlay} opacity="0.8" alt="play" />
                      </a>
                    </Flex>

                    <Flex justifyContent="space-between">
                      <a
                        className="Report-Section3-Button2"
                        href={reportData.report.cta.education.brochure}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.brochure);
                        }}
                        tabIndex={0}
                      >
                        Diversified Income
                      </a>
                      <a
                        className="Report-Section3-Button2"
                        href={reportData.report.cta.education.article}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          LinkClick(reportData.report.cta.education.article);
                        }}
                        tabIndex={0}
                      >
                        Learn Article
                      </a>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
      <Flex className="Mobile-Report-Section4">
        <Text className="Mobile-Report-Section4-Heading">More Details</Text>
        <Flex className="Mobile-Report-Section4-Accordion">
          <Flex className="Mobile-Report-Section4-Accordion-Item">
            <Flex
              className="Mobile-Report-Section4-Accordion-Heading"
              onClick={() => {
                setAccordionTab((prev) =>
                  prev === 'Future Focus' ? '' : 'Future Focus'
                );
              }}
              role="button"
              tabIndex={0}
              aria-expanded="true"
              id="one"
              onKeyPress={(e) => {
                if (e.key === 'Enter' || 'Space') {
                  setAccordionTab((prev) =>
                    prev === 'Future Focus' ? '' : 'Future Focus'
                  );
                  e.currentTarget.setAttribute('aria-expanded', String(true));
                  document
                    .getElementById('two')
                    ?.setAttribute('aria-expanded', String(false));
                  document
                    .getElementById('three')
                    ?.setAttribute('aria-expanded', String(false));
                }
              }}
            >
              <Flex
                className={
                  accordionTab === 'Future Focus'
                    ? 'Mobile-Report-Section4-Accordion-HighLow'
                    : 'Mobile-Report-Section4-Accordion-HighLowUnselected'
                }
              >
                {reportData.report.scores.focus.bucket.toUpperCase()}
              </Flex>
              <Flex direction="column" ml="13px">
                <Text className="Mobile-Report-Section4-Accordion-Title">
                  Future Focus
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Score">
                  {reportData.report.scores.focus.value} / 100
                </Text>
              </Flex>
              <Image
                ml="auto"
                w="20px"
                src={ChevronUpDown}
                transform={
                  accordionTab === 'Future Focus'
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)'
                }
              />
            </Flex>
            {accordionTab === 'Future Focus' && (
              <Flex direction="column">
                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {reportData.report.scores.focus.what_it_means.title}
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.focus.what_it_means.body}
                </Text>

                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {reportData.report.scores.focus.what_is_awesome.title}
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.focus.what_is_awesome.body}
                </Text>

                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {reportData.report.scores.focus.what_to_watchout_for.title}
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.focus.what_to_watchout_for.body}
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex className="Mobile-Report-Section4-Accordion-Item">
            <Flex
              className="Mobile-Report-Section4-Accordion-Heading"
              onClick={() => {
                setAccordionTab((prev) =>
                  prev === 'Tendency to Avoid Risk'
                    ? ''
                    : 'Tendency to Avoid Risk'
                );
              }}
              role="button"
              tabIndex={0}
              aria-expanded="false"
              id="two"
              onKeyPress={(e) => {
                if (e.key === 'Enter' || 'Space') {
                  setAccordionTab((prev) =>
                    prev === 'Tendency to Avoid Risk'
                      ? ''
                      : 'Tendency to Avoid Risk'
                  );
                  e.currentTarget.setAttribute('aria-expanded', String(true));
                  document
                    .getElementById('one')
                    ?.setAttribute('aria-expanded', String(false));
                  document
                    .getElementById('three')
                    ?.setAttribute('aria-expanded', String(false));
                }
              }}
            >
              <Flex
                className={
                  accordionTab === 'Tendency to Avoid Risk'
                    ? 'Mobile-Report-Section4-Accordion-HighLow'
                    : 'Mobile-Report-Section4-Accordion-HighLowUnselected'
                }
              >
                {reportData.report.scores.risk.bucket.toUpperCase()}
              </Flex>
              <Flex direction="column" ml="13px">
                <Text className="Mobile-Report-Section4-Accordion-Title">
                  Tendency to Avoid Risk
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Score">
                  {reportData.report.scores.risk.value} / 100
                </Text>
              </Flex>
              <Image
                ml="auto"
                w="20px"
                src={ChevronUpDown}
                transform={
                  accordionTab === 'Tendency to Avoid Risk'
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)'
                }
              />
            </Flex>
            {accordionTab === 'Tendency to Avoid Risk' && (
              <Flex direction="column">
                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {reportData.report.scores.risk.what_it_means.title}
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.risk.what_it_means.body}
                </Text>

                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {reportData.report.scores.risk.what_is_awesome.title}
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.risk.what_is_awesome.body}
                </Text>

                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {reportData.report.scores.risk.what_to_watchout_for.title}
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.risk.what_to_watchout_for.body}
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex className="Mobile-Report-Section4-Accordion-Item">
            <Flex
              className="Mobile-Report-Section4-Accordion-Heading"
              onClick={() => {
                setAccordionTab((prev) =>
                  prev === 'Desire for Certainty' ? '' : 'Desire for Certainty'
                );
              }}
              role="button"
              tabIndex={0}
              aria-expanded="false"
              id="three"
              onKeyPress={(e) => {
                if (e.key === 'Enter' || 'Space') {
                  setAccordionTab((prev) =>
                    prev === 'Desire for Certainty'
                      ? ''
                      : 'Desire for Certainty'
                  );
                  e.currentTarget.setAttribute('aria-expanded', String(true));
                  document
                    .getElementById('one')
                    ?.setAttribute('aria-expanded', String(false));
                  document
                    .getElementById('two')
                    ?.setAttribute('aria-expanded', String(false));
                }
              }}
            >
              <Flex
                className={
                  accordionTab === 'Desire for Certainty'
                    ? 'Mobile-Report-Section4-Accordion-HighLow'
                    : 'Mobile-Report-Section4-Accordion-HighLowUnselected'
                }
              >
                {reportData.report.scores.certainty.bucket.toUpperCase()}
              </Flex>
              <Flex direction="column" ml="13px">
                <Text className="Mobile-Report-Section4-Accordion-Title">
                  Desire for Certainty
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Score">
                  {reportData.report.scores.certainty.value} / 100
                </Text>
              </Flex>
              <Image
                ml="auto"
                w="20px"
                src={ChevronUpDown}
                transform={
                  accordionTab === 'Desire for Certainty'
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)'
                }
              />
            </Flex>
            {accordionTab === 'Desire for Certainty' && (
              <Flex direction="column">
                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {reportData.report.scores.certainty.what_it_means.title}
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.certainty.what_it_means.body}
                </Text>

                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {reportData.report.scores.certainty.what_is_awesome.title}
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.certainty.what_is_awesome.body}
                </Text>

                <Text className="Mobile-Report-Section4-Accordion-SubHead">
                  {
                    reportData.report.scores.certainty.what_to_watchout_for
                      .title
                  }
                </Text>
                <Text className="Mobile-Report-Section4-Accordion-Desc">
                  {reportData.report.scores.certainty.what_to_watchout_for.body}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex className="Mobile-Report-Section5">
        <Text className="Mobile-Report-Section5-Link">About</Text>
        <Text className="Mobile-Report-Section5-Link" mt="24px">
          Support
        </Text>
        <a
          className="Mobile-Report-Section5-Link"
          style={{marginTop: '24px'}}
          href={reportData.report.cta.footer}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            LinkClick(reportData.report.cta.footer);
          }}
          tabIndex={0}
        >
          Back to <p className="Mobile-Report-Section5-Link-Main">TIAA.org</p>
        </a>
        <Image
          src={SyntoniqLogo}
          alt="Syntoniq"
          position="relative"
          left="3%"
        />
        <Text className="Mobile-Report-Section5-Link">Copyright 2021</Text>
      </Flex>
    </Flex>
  );
};

export default MobileReport;
